import React, { useState, useEffect } from 'react';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Input from 'components/atoms/Input';
import Select from 'components/atoms/Select';
import ToggleButton from 'components/atoms/ToggleButton';
import {
	ChevronRightIcon,
	ChevronLeftIcon,
	PlusIcon,
	ClockIcon,
} from '@heroicons/react/outline';
import {
	IQueryReportSection,
	ITabState,
	IVehicleIncidentSection2,
} from 'types/VehicleIncidents';
import {
	useGetIncidentSection2Mutation,
	useUpdateIncidentSection2Mutation,
} from 'redux/api/vehicleIncidents';
import WitnessCard from 'components/molecules/WitnessCard';
import { ISection2Type } from 'types/VehicleIncidents';

export interface IIncidentSection2 {
	setSection: React.Dispatch<React.SetStateAction<IQueryReportSection>>;
	sectionId: number | null;
	tabStates: ITabState;
	setTabStates: React.Dispatch<React.SetStateAction<ITabState>>;
	previousComponent: string;
	setPreviousComponent: React.Dispatch<React.SetStateAction<string>>;
}

const IncidentSection2: React.FC<IIncidentSection2> = ({
	setSection,
	sectionId,
	setTabStates,
	tabStates,
	setPreviousComponent,
}) => {
	const [getIncidentSection2, { data, isSuccess }] =
		useGetIncidentSection2Mutation();
	const [updateIncidentSection2] = useUpdateIncidentSection2Mutation();
	const [toggle, setToggle] = useState(true);

	useEffect(() => {
		if (sectionId !== null) {
			getIncidentSection2(sectionId);
		}
	}, [getIncidentSection2, sectionId]);

	const [section2, setSection2] = useState({
		person_name: '',
		incident_time: '',
		position: '',
		employment_status_id: '1',
		employment_status: '',
		witness_exists: false,
		witnesses: [
			{
				id: 1,
				witness_name: '',
				witness_time: '',
				witness_position: '',
				witness_employment_status: '',
			},
		],
	});

	const backSection = () => {
		setSection('section1');
		setTabStates({
			...tabStates,
			section1: false,
		});
	};

	//drop down options for employment status. To be changed once known
	const statusOptions = [
		{
			id: '1',
			label: 'status 1',
		},
		{
			id: '2',
			label: 'status 2',
		},
	];

	const handleSection2Update = (
		property: ISection2Type,
		value: string,
		index?: number
	) => {
		const newSection2 = section2;
		const newWitnesses = [...newSection2.witnesses];
		if (
			property === 'person_name' ||
			property === 'employment_status' ||
			property === 'incident_time' ||
			property === 'position'
		) {
			newSection2[property] = value;
		} else if (
			property === 'witness_name' ||
			property === 'witness_time' ||
			property === 'witness_position' ||
			property === 'witness_employment_status'
		) {
			if (index !== undefined) {
				newWitnesses[index] = {
					...newWitnesses[index],
					[property]: value,
				};
			}
		}
		newSection2.witnesses = newWitnesses;
		setSection2(newSection2);
	};

	const addWitness = () => {
		const { witnesses } = section2;
		let assignId = 1;
		if (witnesses && witnesses.length > 0) {
			assignId = witnesses[witnesses.length - 1].id + 1;
		} else {
			assignId += 1;
		}

		setSection2((section2) => {
			return {
				...section2,
				witnesses: [
					...section2.witnesses,
					{
						id: assignId,
						witness_name: '',
						witness_time: '',
						witness_position: '',
						witness_employment_status: '',
					},
				],
			};
		});
	};

	const deleteWitness = (id: number) => {
		const filteredList = section2.witnesses.filter(
			(witness) => witness.id !== id
		);
		setSection2((prevSection2) => {
			return {
				...prevSection2,
				witnesses: filteredList,
			};
		});
	};

	const [errorState, setErrorState] = useState({
		person_name: false,
		incident_time: false,
		position: false,
	});

	useEffect(() => {
		if (!data) {
			return;
		}

		if (isSuccess && data) {
			const {
				person_name,
				incident_time,
				position,
				employment_status,
				witness_exists,
				witnesses,
			} = data?.data;

			setSection2({
				...section2,
				person_name: person_name || '',
				incident_time: incident_time || '',
				position: position || '',
				employment_status: employment_status || '',
				witness_exists: witness_exists,
				witnesses:
					witnesses !== undefined && witnesses.length > 0
						? witnesses
						: section2.witnesses,
			});

			setToggle(witness_exists);
		}
		//removes dependency for "section 2"
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleCreateIncidentSection2 = async () => {
		const { person_name, incident_time, position, employment_status } =
			section2;
		const allPersonsRequired = [
			person_name,
			incident_time,
			employment_status,
			position,
		].every(Boolean);

		if (allPersonsRequired) {
			let requiredWitnessField = true;
			const formattedWitnesses = section2?.witnesses.map((witness) => {
				for (const [key, value] of Object.entries(witness)) {
					if (key !== 'id' && value === '') {
						requiredWitnessField = false;
						break;
					}
				}

				const { witness_time } = witness;
				const formatTime =
					witness_time.length < 6 ? `${witness_time}:00.000` : witness_time;
				return {
					...witness,
					witness_time: formatTime,
				};
			});

			if (!requiredWitnessField) {
				return;
			}

			const formatIncidentTime =
				incident_time.length < 6 ? `${incident_time}:00.000` : incident_time;

			if (!sectionId) {
				return;
			}

			const payload: IVehicleIncidentSection2 = {
				id: sectionId,
				person_name: section2.person_name,
				incident_time: formatIncidentTime,
				position: section2.position,
				employment_status: section2.employment_status,
				witness_exists: toggle,
				witnesses: formattedWitnesses,
			};

			await updateIncidentSection2(payload).catch((err) => {
				throw new Error(err);
			});

			if (!toggle) {
				setSection('section4');
				setPreviousComponent('section2');
				setTabStates({
					...tabStates,
					section2: true,
					section3: true,
				});
			} else {
				setSection('section3');
				setTabStates({
					...tabStates,
					section2: true,
				});
			}
		} else {
			setErrorState({
				...errorState,
				person_name: person_name === '',
				incident_time: incident_time === '',
				position: position === '',
			});
		}
	};

	return (
		<>
			<Heading type="h2">Person Involved</Heading>
			<form>
				<div className="flex gap-x-8 flex-wrap">
					<Input
						type="text"
						onChange={(value: string) =>
							handleSection2Update('person_name', value)
						}
						value={section2.person_name}
						className="basis-1/5 grow"
						label="Name of Person"
						required={true}
						errorMessage={errorState.person_name ? 'Please enter name' : ''}
					/>
					<Input
						type="time"
						onChange={(value: string) =>
							handleSection2Update('incident_time', value)
						}
						value={section2.incident_time}
						className="basis-1/5 grow mb-10"
						required={true}
						label="Incident Time"
						Icon={ClockIcon}
						iconClassName="text-black"
						iconPosition="trailing"
						placeholder="Select time"
						errorMessage={
							errorState.incident_time ? 'Please enter the incident time' : ''
						}
					/>
					<Input
						type="text"
						onChange={(value: string) =>
							handleSection2Update('position', value)
						}
						value={section2.position}
						className="basis-1/5 grow"
						label="Position"
						required={true}
						errorMessage={
							errorState.position ? 'Please enter the position' : ''
						}
					/>
					<div className="basis-1/5 grow">
						<Select
							label="Employment Status"
							options={statusOptions}
							placeholder="Select"
							onChange={(value: string) =>
								handleSection2Update('employment_status', value)
							}
							required={true}
							selected={section2.employment_status}
						/>
					</div>
					<div className="h-0 border-t border-primary-200 w-full my-5" />

					<ToggleButton
						toggle={toggle}
						setToggle={setToggle}
						label="Were there any witnesses?"
					/>
				</div>
				{toggle && (
					<>
						{section2.witnesses.map((witness, index) => {
							const { id } = witness;
							return (
								<WitnessCard
									key={id}
									handleSection2Update={handleSection2Update}
									employmentStatusOptions={statusOptions}
									employmentStatusId={'1'}
									deleteWitness={deleteWitness}
									id={id}
									witness={witness}
									index={index}
								/>
							);
						})}

						<Button
							onClick={addWitness}
							type="secondary"
							className="font-bold mr-4"
						>
							<PlusIcon height={18} width={18} className="mr-2" />
							Add another witness
						</Button>
					</>
				)}

				<div className="h-0 border-t-[1px] border-primary-200 w-full my-5" />
				<div className="flex">
					<Button
						onClick={backSection}
						type="secondary"
						className="font-bold mr-4"
					>
						<ChevronLeftIcon height={18} width={24} />
						Back
					</Button>
					<Button onClick={handleCreateIncidentSection2} className="font-bold">
						Continue to Incident / Injury Details
						<ChevronRightIcon height={18} width={32} />
					</Button>
				</div>
			</form>
		</>
	);
};

export default IncidentSection2;
