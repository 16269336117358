import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import ToggleButton from 'components/atoms/ToggleButton';
import React, { useEffect, useState } from 'react';
import {
	IQueryReportSection,
	bodyParts,
	bodyPartsInjured,
	injuryType,
	incidentMechanism,
	incidentAgency,
	IVehicleIncidentSection3,
	IOption,
	ITabState,
} from 'types/VehicleIncidents';
import FormCheckBoxGroup from 'components/molecules/FormCheckBoxGroup';
import Divider from 'components/atoms/Divider';
import {
	useGetIncidentSection3Mutation,
	useUpdateIncidentSection3Mutation,
} from 'redux/api/vehicleIncidents';
import Button from 'components/atoms/Button';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import SlideDown from 'components/atoms/SlideDown';

export interface IIncidentSection3 {
	setSection: React.Dispatch<React.SetStateAction<IQueryReportSection>>;
	sectionId: number | null;
	tabStates: ITabState;
	setTabStates: React.Dispatch<React.SetStateAction<ITabState>>;
	setPreviousComponent: React.Dispatch<React.SetStateAction<string>>;
}

export type ISection3Type =
	| 'bodyPartsInjuredOtherInput'
	| 'injuryTypeOtherInput'
	| 'incidentMechanismOtherInput'
	| 'incidentAgencyOtherInput'
	| 'injuryType'
	| 'bodyParts'
	| 'bodyPartsInjured'
	| 'incidentMechanism'
	| 'incidentAgency';

const IncidentSection3: React.FC<IIncidentSection3> = ({
	sectionId,
	setSection,
	setTabStates,
	tabStates,
	setPreviousComponent,
}) => {
	const [isNuisanceReport, setIsNuisanceReport] = useState(false);
	const [isInjury, setIsInjury] = useState(false);
	const [getIncidentSection3, { data, isSuccess }] =
		useGetIncidentSection3Mutation();
	const [updateIncidentSection3] = useUpdateIncidentSection3Mutation();

	useEffect(() => {
		if (sectionId !== null) {
			getIncidentSection3(sectionId);
		}
	}, [getIncidentSection3, sectionId]);

	const [errorState, setErrorState] = useState({
		bodyParts: false,
		bodyPartsInjured: false,
		injuryType: false,
		incidentMechanism: false,
		incidentAgency: false,
	});

	let injuryTypeChecked = false;
	let bodyPartsChecked = false;
	let incidentMechanismChecked = false;
	let incidentAgencyChecked = false;

	const [section3, setSection3] = useState({
		bodyParts: bodyParts,
		bodyPartsInjured: bodyPartsInjured,
		bodyPartsInjuredOtherInput: '',
		injuryType: injuryType,
		injuryTypeOtherInput: '',
		incidentMechanism: incidentMechanism,
		incidentMechanismOtherInput: '',
		incidentAgency: incidentAgency,
		incidentAgencyOtherInput: '',
	});

	const backSection = () => {
		setSection('section2');
		setTabStates({
			...tabStates,
			section2: false,
		});
	};

	useEffect(() => {
		if (isSuccess && data) {
			const {
				nuisance_report,
				injuries,
				body_injured,
				injury_type,
				incident_mechanism,
				incident_agency,
				injury_type_other_description,
				incident_mechanism_other_description,
				incident_agency_other_description,
				body_injured_other_description,
			} = data.data;

			let bodyPartInjuredArray: IOption[] = [];
			let bodyPartInjuredArray2 = [...body_injured];
			const arrayToFilter = ['Left', 'First Right', 'Upper', 'Lower'];

			for (let i = body_injured.length - 1; i >= 0; i--) {
				if (arrayToFilter.includes(body_injured[i].title)) {
					bodyPartInjuredArray.push(body_injured[i]);
					bodyPartInjuredArray2.splice(i, 1);
				}
			}
			bodyPartInjuredArray.reverse();

			setSection3({
				...section3,
				bodyParts: bodyPartInjuredArray,
				bodyPartsInjured: bodyPartInjuredArray2,
				bodyPartsInjuredOtherInput: body_injured_other_description,
				injuryType: injury_type,
				injuryTypeOtherInput: injury_type_other_description,
				incidentMechanism: incident_mechanism,
				incidentMechanismOtherInput: incident_mechanism_other_description,
				incidentAgency: incident_agency,
				incidentAgencyOtherInput: incident_agency_other_description,
			});
			setIsInjury(injuries);
			setIsNuisanceReport(nuisance_report);
		}
		//removes dependency for "section 3"
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleSection3Update = (
		property: string,
		value: string | IOption[]
	) => {
		const newSection3 = section3;
		if (
			property === 'bodyPartsInjuredOtherInput' ||
			property === 'incidentAgencyOtherInput' ||
			property === 'injuryTypeOtherInput' ||
			property === 'incidentMechanismOtherInput'
		) {
			if (typeof value === 'string') {
				newSection3[property] = value;
				setSection3(newSection3);
			}
		} else if (
			property === 'bodyPartsInjured' ||
			property === 'bodyParts' ||
			property === 'incidentAgency' ||
			property === 'incidentMechanism' ||
			property === 'injuryType'
		) {
			setSection3({
				...section3,
				[property]: value,
			});
		}
	};

	const isCheckBoxChecked = (checkBoxGroup: IOption[]) => {
		if (
			checkBoxGroup.every((item) => {
				return item.checked === false;
			})
		) {
			return true;
		}
		return false;
	};

	const handleCreateIncidentSection3 = async () => {
		const bodyPartsCheckBoxGroup = section3.bodyParts as IOption[];
		const bodyPartsInjuredCheckBoxGroup =
			section3.bodyPartsInjured as IOption[];
		const bodyInjuredCheckboxGroup = [
			...bodyPartsCheckBoxGroup,
			...bodyPartsInjuredCheckBoxGroup,
		];
		const injuryTypeCheckBoxGroup = section3.injuryType as IOption[];
		const incidentMechanismCheckBoxGroup =
			section3.incidentMechanism as IOption[];
		const incidentAgencyCheckBoxGroup = section3.incidentAgency as IOption[];

		if (!sectionId) {
			return;
		}

		if (isNuisanceReport || !isInjury) {
			const payload: IVehicleIncidentSection3 = {
				id: sectionId,
				nuisance_report: isNuisanceReport,
				injuries: isInjury,
				body_injured: bodyInjuredCheckboxGroup,
				injury_type: injuryTypeCheckBoxGroup,
				incident_mechanism: incidentMechanismCheckBoxGroup,
				incident_agency: incidentAgencyCheckBoxGroup,
				injury_type_other_description: section3.injuryTypeOtherInput,
				incident_mechanism_other_description:
					section3.incidentMechanismOtherInput,
				incident_agency_other_description: section3.incidentAgencyOtherInput,
				body_injured_other_description: section3.bodyPartsInjuredOtherInput,
			};

			await updateIncidentSection3(payload).catch((err) => {
				throw new Error(err);
			});

			if (isNuisanceReport) {
				setSection('section5');
				setPreviousComponent('section3');
				setTabStates({
					...tabStates,
					section3: true,
					section4: true,
				});
				return;
			}

			if (!isInjury) {
				setSection('section4');
				setPreviousComponent('section3');
				setTabStates({
					...tabStates,
					section3: true,
				});
				return;
			}
		}

		injuryTypeChecked = isCheckBoxChecked(injuryTypeCheckBoxGroup);
		bodyPartsChecked = isCheckBoxChecked(bodyInjuredCheckboxGroup);
		incidentMechanismChecked = isCheckBoxChecked(
			incidentMechanismCheckBoxGroup
		);
		incidentAgencyChecked = isCheckBoxChecked(incidentAgencyCheckBoxGroup);

		if (
			!injuryTypeChecked &&
			!bodyPartsChecked &&
			!incidentMechanismChecked &&
			!incidentAgencyChecked
		) {
			const payload: IVehicleIncidentSection3 = {
				id: sectionId,
				nuisance_report: isNuisanceReport,
				injuries: isInjury,
				body_injured: bodyInjuredCheckboxGroup,
				injury_type: injuryTypeCheckBoxGroup,
				incident_mechanism: incidentMechanismCheckBoxGroup,
				incident_agency: incidentAgencyCheckBoxGroup,
				injury_type_other_description: section3.injuryTypeOtherInput,
				incident_mechanism_other_description:
					section3.incidentMechanismOtherInput,
				incident_agency_other_description: section3.incidentAgencyOtherInput,
				body_injured_other_description: section3.bodyPartsInjuredOtherInput,
			};

			await updateIncidentSection3(payload).catch((err) => {
				throw new Error(err);
			});

			setSection('section4');
			setPreviousComponent('section3');
			setTabStates({
				...tabStates,
				section3: true,
			});
		} else {
			setErrorState({
				...errorState,
				bodyPartsInjured: bodyPartsChecked,
				injuryType: injuryTypeChecked,
				incidentMechanism: incidentMechanismChecked,
				incidentAgency: incidentAgencyChecked,
			});
		}
	};

	const errorStyle = 'p-3 border border-red-500 rounded-lg';

	return (
		<>
			<Heading type="h2">Injury / Incident Details</Heading>

			<ToggleButton
				toggle={isNuisanceReport}
				setToggle={setIsNuisanceReport}
				label="Was this a nuisance report?"
			/>

			{!isNuisanceReport && (
				<>
					<Divider />

					<ToggleButton
						toggle={isInjury}
						setToggle={setIsInjury}
						label="Were there any injuries?"
					/>

					<>
						<SlideDown
							open={isInjury}
							className={`${isInjury ? 'my-2' : 'hidden'}`}
						>
							<Text
								className="font-inter leading-[26px] tracking-[-1px] text-secondary pb-5"
								type="bold"
							>
								Part of Body Injured *
							</Text>
							<div
								className={errorState.bodyPartsInjured ? `${errorStyle}` : ''}
								style={
									errorState.bodyPartsInjured
										? { padding: '2rem' }
										: { padding: '0' }
								}
							>
								<FormCheckBoxGroup
									checkBoxName="bodyParts"
									checkBoxGroupData={section3.bodyParts}
									onHandleChange={handleSection3Update}
									gridColumns={'8'}
								/>
								<div className="h-0 border-t border-primary-200 w-full my-5" />
								<FormCheckBoxGroup
									checkBoxName="bodyPartsInjured"
									checkBoxGroupData={section3.bodyPartsInjured}
									onHandleChange={handleSection3Update}
									gridColumns={'8'}
									otherTextBox={true}
									otherTextBoxValue={section3.bodyPartsInjuredOtherInput}
									otherTextBoxOnChange={(value: string) =>
										handleSection3Update('bodyPartsInjuredOtherInput', value)
									}
								/>
							</div>
							{errorState.bodyPartsInjured && (
								<Text className="text-red-500">
									Please tick at least one checkbox *
								</Text>
							)}
							<div className="h-0 border-t border-primary-200 w-full my-5" />
							<Text
								className="font-inter leading-[26px] tracking-[-1px] text-secondary pb-5"
								type="bold"
							>
								Injury Type *
							</Text>
							<FormCheckBoxGroup
								checkBoxName="injuryType"
								checkBoxGroupData={section3.injuryType}
								onHandleChange={handleSection3Update}
								gridColumns={'8'}
								otherTextBox={true}
								otherTextBoxValue={section3.injuryTypeOtherInput}
								otherTextBoxOnChange={(value: string) =>
									handleSection3Update('injuryTypeOtherInput', value)
								}
								otherTextBoxClassName="w-[400px] lg:-ml-24"
								errorState={errorState.injuryType}
							/>
							<div className="h-0 border-t border-primary-200 w-full my-5" />
							<Text
								className="font-inter leading-[26px] tracking-[-1px] text-secondary pb-5"
								type="bold"
							>
								Incident Mechanism *
							</Text>
							<FormCheckBoxGroup
								checkBoxName="incidentMechanism"
								checkBoxGroupData={section3.incidentMechanism}
								onHandleChange={handleSection3Update}
								gridColumns={'5'}
								otherTextBox={true}
								otherTextBoxValue={section3.incidentMechanismOtherInput}
								otherTextBoxOnChange={(value: string) =>
									handleSection3Update('incidentMechanismOtherInput', value)
								}
								errorState={errorState.incidentMechanism}
								otherTextBoxClassName="w-[400px] xl:-ml-44"
							/>

							<div className="h-0 border-t border-primary-200 w-full my-5" />
							<Text
								className="font-inter leading-[26px] tracking-[-1px] text-secondary pb-5"
								type="bold"
							>
								Incident Agency *
							</Text>
							<FormCheckBoxGroup
								checkBoxName="incidentAgency"
								checkBoxGroupData={section3.incidentAgency}
								onHandleChange={handleSection3Update}
								gridColumns={'5'}
								otherTextBox={true}
								otherTextBoxValue={section3.incidentAgencyOtherInput}
								otherTextBoxOnChange={(value: string) =>
									handleSection3Update('incidentAgencyOtherInput', value)
								}
								errorState={errorState.incidentAgency}
								otherTextBoxClassName="w-[400px] lg:-ml-44 rounded-lg"
							/>
						</SlideDown>
					</>
				</>
			)}

			<Divider />
			<div className="flex">
				<Button
					onClick={backSection}
					type="secondary"
					className="font-bold mr-4"
				>
					<ChevronLeftIcon height={18} width={24} />
					Back
				</Button>
				<Button onClick={handleCreateIncidentSection3} className="font-bold">
					{isNuisanceReport
						? 'Continue to Investigation'
						: 'Continue to Analysis'}
					<ChevronRightIcon height={18} width={32} />
				</Button>
			</div>
		</>
	);
};

export default IncidentSection3;
