import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import ActivityCard from 'components/molecules/ActivityCard';
import React, { useEffect, useState } from 'react';
import { IVehicleFaultActivity } from 'redux/api/vehicleFaults';
import { IVehicleSurveySubmissionActivity } from 'redux/api/surveys';
import { IVehicleIncidentActivity } from 'redux/api/vehicleIncidents';
import { useAppSelector } from 'redux/hooks';

export interface ICommentPayload {
	vehicle_fault?: number;
	incident?: number;
	survey_submission?: number;
	type: 'comment';
	content: string;
	user: number;
}
export interface ICommentPanel {
	id: number | null;
	onClose: () => void;
	createActivity: (payload: ICommentPayload) => void;
	updateActivity: (payload: { activityId: number; content: string }) => void;
	deleteActivity: (payload: number) => void;
	activityData:
		| IVehicleSurveySubmissionActivity[]
		| IVehicleIncidentActivity[]
		| IVehicleFaultActivity[]
		| undefined;
	getActivity: (payload: number) => void;
	activityType: 'vehicle_fault' | 'survey_submission' | 'incident';
	hasPermissionAccess: () => boolean;
}

const CommentPanel: React.FC<ICommentPanel> = ({
	id,
	onClose,
	createActivity,
	updateActivity,
	deleteActivity,
	activityData,
	getActivity,
	activityType,
	hasPermissionAccess,
}) => {
	const [activityId, setActivityId] = useState<number>();
	const [activity, setActivity] = useState<
		| IVehicleFaultActivity[]
		| IVehicleSurveySubmissionActivity[]
		| IVehicleIncidentActivity[]
	>([]);
	const [comment, setComment] = useState('');
	const user = useAppSelector((state) => state.user.id);

	useEffect(() => {
		if (id && id !== activityId) {
			setActivityId(id);
			getActivity(id);
		}
	}, [id, getActivity, setActivityId, activityId]);

	useEffect(() => {
		if (activityData) {
			setActivity(activityData);
		}
	}, [activityData, setActivity]);

	const handleCreateComment = async () => {
		if (!user || !comment) {
			return;
		}
		if (id) {
			const payload: ICommentPayload = {
				type: 'comment',
				content: `${comment}`,
				user,
			};
			payload[activityType] = id;
			await createActivity(payload);
			setComment('');
			getActivity(id);
		}
	};

	const handleDeleteComment = async (activityId: number) => {
		if (id) {
			await deleteActivity(activityId);
			getActivity(id);
		}
	};

	const handleEditComment = async (activityId: number, comment: string) => {
		const payload = {
			activityId: activityId,
			content: comment,
		};

		if (id) {
			await updateActivity(payload);
			getActivity(id);
		}
	};

	return (
		<div className="px-4 space-y-6">
			<Heading type="h2">Comments</Heading>
			<div className="flex flex-col">
				<div>
					<Text className="mb-2">Write your comments</Text>
					<textarea
						className="h-[150px] w-[700px] rounded-sm resize-none border border-gray-400"
						value={comment}
						onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
							setComment(e.target.value)
						}
						readOnly={!hasPermissionAccess()}
					></textarea>
					<div className="flex space-x-4 mt-4 mb-6">
						<Button
							type="quaternary"
							className="font-bold"
							onClick={onClose}
							size="lg"
						>
							Cancel
						</Button>
						<Button
							navigate="next"
							onClick={handleCreateComment}
							isDisabled={!comment}
							className="font-bold"
							size="lg"
						>
							Save
						</Button>
					</div>
				</div>
				<div className="bg-white p-6 space-y-4 rounded-sm w-[700px] h-[450px] overflow-y-scroll border border-gray-500">
					{activity.length > 0 ? (
						activity.map((activty) => (
							<ActivityCard
								key={activty.id}
								id={activty.id}
								type={activty.type}
								content={activty.content}
								user={activty.user?.first_name || ''}
								avatar={activty.user?.avatar?.url}
								date={activty.createdAt || ''}
								handleDeleteComment={handleDeleteComment}
								handleEditComment={handleEditComment}
								hasWriteAccess={hasPermissionAccess}
							/>
						))
					) : (
						<div className="w-full text-center">
							<Text>No Activity</Text>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CommentPanel;
