import React from 'react';
import { ReactComponent as Incidents } from 'assets/images/Incidents.svg';
import { ReactComponent as VehicleFaults } from 'assets/images/Faults.svg';
import { ReactComponent as Surveys } from 'assets/images/Surveys.svg';
import { ReactComponent as ManageUsers } from 'assets/images/Users.svg';
import { ReactComponent as Links } from 'assets/images/Links.svg';
import { iconTypes } from 'types/Layout';

type iconStates = 'unselected' | 'hover' | 'active';

type sizes = 'default' | 'lg';
export interface ICustomIcon {
	iconTypes: iconTypes;
	iconState: iconStates;
	size?: sizes;
}

const components = {
	Incidents: Incidents,
	'Vehicle Faults': VehicleFaults,
	Surveys: Surveys,
	Users: ManageUsers,
	Links: Links,
};

const CustomIcon: React.FC<ICustomIcon> = ({
	iconTypes,
	iconState,
	size = 'default',
}) => {
	const iconSize = {
		default: `w-[42px] h-[42px]`,
		lg: `w-[54px] h-[54px]`,
	};

	const states = {
		unselected: 'fill-secondary-500 rounded-md',
		hover: 'fill-primary-300 rounded-md',
		active: 'fill-primary-800 bg-primary-500 rounded-md',
	};
	const Icon = components[iconTypes];
	return (
		<Icon
			className={`${states[iconState]} rounded-md ${iconSize[size]} p-[7px]`}
		/>
	);
};

export default CustomIcon;
