import { configureStore } from '@reduxjs/toolkit';
import { baseAPI } from './api/baseAPI';
import userReducer from './slices/userSlice';
import vehicleFaultSlice from './slices/vehicleFaultSlice';
import vehicleIncidentSlice from './slices/vehicleIncidentsSlice';
import surveysSlice from 'redux/slices/surveys';

export const store = configureStore({
	reducer: {
		user: userReducer,
		vehicleFault: vehicleFaultSlice,
		vehicleIncident: vehicleIncidentSlice,
		surveys: surveysSlice,
		[baseAPI.reducerPath]: baseAPI.reducer,
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
