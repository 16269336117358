export type IQueryPeriod = 'today' | 'week' | 'month' | 'all';
export interface IVehicleFault {
	id: number;
	alloc8_id: string;
	submitted_at: string;
	createdAt: string;
	type: 'vehicle-fault';
	updatedAt: string;
	status: 'open' | 'closed';
	priority: 'normal' | 'high';
	ticket_number: string;
	registration: string;
	branch: {
		id: string;
		name: string;
	};
	fault_created_by?: string;
	vehicle_type: string;
	operator_name: string;
	vehicle_description: string;
	km_start: string;
	numberOfFaults: number;
	lodge_fault: '' | 'Yes' | 'No';
	fault_description: string;
	vehicle_faults: {
		id: number;
		fault_type: string;
		priority: string;
	}[];
	faults: {
		question: string;
		answer: string;
	}[];
	managers: {
		id: string;
		first_name: string;
		last_name: string;
	}[];
	assignee?: {
		id: string;
		first_name: string;
		last_name: string;
	};
}

export interface IVehicleCreateFaultPayload {
	ticket_number: string;
	incidentDate: string | undefined;
	registration: string;
	vehicleType: string;
	branch: number | null;
	description: string;
	faults: {
		id: number;
		fault_type: string;
		priority: string;
	}[];
	assignee: number | null;
	status: string;
}

export interface IVehicleFaultQuestion {
	id: number;
	question: string;
	alloc8_id: string;
	variable_name: string | null;
	fault_question: boolean;
	createdAt: string;
	updatedAt: string;
}

export interface IVehicleFaultTypes {
	fault_options: { id: string; label: string }[];
}

export interface ISortFaultData {
	branch: IOption[];
	priority: IOption[];
}

export interface IFilters {
	[name: string]: IOption[] | string | null;
}
export interface IOption {
	id: number;
	title: string;
	checked: boolean;
}

const branches = [
	{ id: 1, title: 'Adelaide', checked: false },
	{ id: 2, title: 'Adelaide Away Work', checked: false },
	{ id: 3, title: 'Altus People', checked: false },
];

const vehicles = [
	{ id: 1, title: 'Ute', checked: false },
	{ id: 2, title: 'TMA', checked: false },
	{ id: 3, title: 'Drop Deck', checked: false },
];

const priorities = [
	{ id: 1, title: 'normal', checked: false },
	{ id: 2, title: 'high', checked: false },
];

const statuses = [
	{ id: 1, title: 'open', checked: false },
	{ id: 2, title: 'closed', checked: false },
];

export const initialFilterState: IFilters = {
	branch: branches,
	vehicle: vehicles,
	priority: priorities,
	status: statuses,
	to: null,
	from: null,
	registration: '',
};

export const vehicleFaultRadioButtons = [
	{
		id: 'open',
		title: 'Open',
	},
	{
		id: 'closed',
		title: 'Closed',
	},
];

export const vehicleTypeOptions = [
	{
		id: '1',
		label: 'Ute',
	},
	{
		id: '2',
		label: 'TMA',
	},
	{
		id: '3',
		label: 'Drop Deck',
	},
];

export const vehiclePriorityOptions = [
	{
		id: '1',
		label: 'Low',
	},
	{
		id: '2',
		label: 'Med',
	},
	{
		id: '3',
		label: 'High',
	},
];
