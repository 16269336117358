import React, { useState, useEffect } from 'react';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import DateInput from 'components/atoms/DateInput';
import Input from 'components/atoms/Input';
import Select from 'components/atoms/Select';
import TextareaInput from 'components/atoms/TextareaInput';
import ToggleButton from 'components/atoms/ToggleButton';
import { ChevronRightIcon, ClockIcon } from '@heroicons/react/outline';
import {
	IQueryReportSection,
	ISection1Type,
	IVehicleIncidentSection1,
	ITabState,
} from 'types/VehicleIncidents';
import {
	useCreateIncidentMutation,
	useCreateIncidentSection1Mutation,
	useGetIncidentSection1Mutation,
	useUpdateIncidentSection1Mutation,
	useUpdateIncidentMutation,
	useGetBranchesQuery,
	useCreateIncidentSection2Mutation,
	useCreateIncidentSection3Mutation,
	useCreateIncidentSection4Mutation,
	useCreateIncidentSection5Mutation,
} from 'redux/api/vehicleIncidents';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
	bodyInjuredData,
	injuryTypeData,
	incidentMechanismData,
	incientAgencyData,
} from 'components/organisms/IncidentSection3/incidentSection3CheckBoxData';
import {
	codeOfConductData,
	incidentCategoryData,
	lifeSavingRulesData,
} from 'components/organisms/IncidentSection4/incidentSection4CheckBoxData';
import { hierachyOfControlData } from 'components/organisms/IncidentSection5/incidentSection5CheckBoxData';
import { updateIncidentSectionId } from 'redux/slices/vehicleIncidentsSlice';

export interface IIncidentSection1 {
	className?: string;
	setSection: React.Dispatch<React.SetStateAction<IQueryReportSection>>;
	sectionId: number | null;
	tabStates: ITabState;
	setTabStates: React.Dispatch<React.SetStateAction<ITabState>>;
}

const IncidentSection1: React.FC<IIncidentSection1> = ({
	className = '',
	setSection,
	sectionId,
	setTabStates,
	tabStates,
}) => {
	const [updateIncidentSection1] = useUpdateIncidentSection1Mutation();
	const [createIncidentSection1] = useCreateIncidentSection1Mutation();
	const [createIncidentSection2] = useCreateIncidentSection2Mutation();
	const [createIncidentSection3] = useCreateIncidentSection3Mutation();
	const [createIncidentSection4] = useCreateIncidentSection4Mutation();
	const [createIncidentSection5] = useCreateIncidentSection5Mutation();
	const [getIncidentSection1, { data, isLoading, isSuccess }] =
		useGetIncidentSection1Mutation();
	const [toggle, setToggle] = useState(false);
	const IncidentState = useAppSelector((state) => state.vehicleIncident);
	const [createIncident] = useCreateIncidentMutation();
	const [updateIncident] = useUpdateIncidentMutation();
	const { data: branchData, isSuccess: branchIsSuccess } =
		useGetBranchesQuery();
	const dispatch = useAppDispatch();

	//formats to YYYY-MM-DD
	const formatMyDate = (value: string | Date, locale = 'en-CA') => {
		const date = new Date(value).toLocaleDateString(locale);
		return date;
	};

	const handleSection1Update = (
		property: ISection1Type,
		value: Date | string
	) => {
		const newSection1 = section1;
		if (property === 'incident_date' || property === 'reported_date') {
			if (typeof value === 'object') {
				newSection1[property] = value;
			}
		} else {
			if (typeof value === 'string') {
				newSection1[property] = value;
			}
		}

		setSection1(newSection1);
	};

	const [section1, setSection1] = useState({
		description: '',
		location: '',
		client: '',
		jobDetails: '',
		equipmentNumber: '',
		ticketNumber: '',
		personReported: '',
		project: '',
		incident_date: new Date(),
		reported_date: new Date(),
		branch: '',
		incident_time: '',
		mv_incident: false,
		branch_options: [
			{
				id: '',
				label: '',
			},
		],
	});

	useEffect(() => {
		if (!IncidentState.isNewIncident && sectionId !== null) {
			getIncidentSection1(sectionId);
		} else {
			if (branchIsSuccess && branchData) {
				setSection1({
					...section1,
					branch_options: branchData,
				});
			}
		}
		//disables dependency for "section 1" and IncidentState.isNewIncident
		// eslint-disable-next-line
	}, [getIncidentSection1, sectionId, branchIsSuccess, branchData]);

	useEffect(() => {
		if (isSuccess && data) {
			const {
				branch,
				incident_date,
				reported_date,
				incident_time,
				mv_incident,
				incident_description,
				location,
				client_name,
				job_details,
				equipment_number,
				ticket_number,
				project,
				name_of_person_reported,
				branch_options,
			} = data?.data;

			const formatIncidentDate = new Date(incident_date);
			const formatReportedDate = new Date(reported_date);

			setSection1({
				...section1,
				description: incident_description || '',
				location: location || '',
				client: client_name,
				jobDetails: job_details,
				equipmentNumber: equipment_number || '',
				ticketNumber: ticket_number || '',
				personReported: name_of_person_reported || '',
				branch: branch,
				incident_date: formatIncidentDate,
				reported_date: formatReportedDate,
				incident_time: incident_time,
				project: project || '',
				branch_options: branch_options,
			});
			setToggle(mv_incident);
		}
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleCreateIncidentSection1 = async (isSaveButton: boolean) => {
		const allRequired = [
			section1.incident_date,
			section1.reported_date,
			section1.incident_time !== '',
			section1.location !== '',
			section1.description !== '',
			section1.client !== '',
			section1.jobDetails !== '',
		].every(Boolean);

		if (allRequired) {
			const formatIncidentDate = formatMyDate(section1.incident_date);
			const formatReportedDate = formatMyDate(section1.reported_date);

			const formatIncidentTime =
				section1.incident_time.length < 6
					? `${section1.incident_time}:00.000`
					: section1.incident_time;

			let branchName = '';
			section1.branch_options.forEach((item) => {
				if (item.id === section1.branch) {
					branchName = item.label;
				}
			});

			if (branchName === '') {
				branchName = section1.branch;
			}

			const payload: IVehicleIncidentSection1 = {
				id: sectionId,
				branch: branchName,
				incident_date: formatIncidentDate,
				reported_date: formatReportedDate,
				incident_time: formatIncidentTime,
				location: section1.location,
				incident_description: section1.description,
				client_name: section1.client,
				job_details: section1.jobDetails,
				mv_incident: toggle,
				equipment_number: section1.equipmentNumber,
				ticket_number: section1.ticketNumber,
				name_of_person_reported: section1.personReported,
				project: section1.project,
				branch_options: section1.branch_options,
			};

			if (IncidentState.isNewIncident) {
				const createNewIncident = await createIncident().catch((err) => {
					throw new Error(err);
				});

				const newIncidentId =
					'data' in createNewIncident ? createNewIncident.data?.id : 0;

				payload.id = newIncidentId;
				const createSection1Data = await createIncidentSection1(payload).catch(
					(err) => {
						throw new Error(err);
					}
				);

				const branchOption = section1.branch_options.filter(
					(branch) => branch.label === branchName
				);
				const branchId =
					branchData !== undefined ? parseInt(branchOption[0].id) : null;

				const updateIncidentPayload = {
					id: newIncidentId,
					branch: branchId,
					ticket_number: section1.ticketNumber,
					client_name: section1.client,
					description: section1.description,
				};

				await updateIncident(updateIncidentPayload).catch((err) => {
					throw new Error(err);
				});

				const createSection3Payload = {
					id: newIncidentId,
					body_injured: bodyInjuredData,
					injury_type: injuryTypeData,
					incident_mechanism: incidentMechanismData,
					incident_agency: incientAgencyData,
				};

				const createSection4Payload = {
					id: newIncidentId,
					incident_category: incidentCategoryData,
					life_saving_rules: lifeSavingRulesData,
					code_of_conduct: codeOfConductData,
				};

				const createSection5Payload = {
					id: newIncidentId,
					hierachy_of_control: hierachyOfControlData,
				};

				const createSection2Data = await createIncidentSection2({
					id: newIncidentId,
				}).catch((err) => {
					throw new Error(err);
				});
				const createSection3Data = await createIncidentSection3(
					createSection3Payload
				).catch((err) => {
					throw new Error(err);
				});
				const createSection4Data = await createIncidentSection4(
					createSection4Payload
				).catch((err) => {
					throw new Error(err);
				});
				const createSection5Data = await createIncidentSection5(
					createSection5Payload
				).catch((err) => {
					throw new Error(err);
				});

				const updateIncidentStatePayload = {
					incidentReportId: newIncidentId,
					incidentSection1Id:
						'data' in createSection1Data ? createSection1Data.data?.id : 0,
					incidentSection2Id:
						'data' in createSection2Data ? createSection2Data.data?.id : 0,
					incidentSection3Id:
						'data' in createSection3Data ? createSection3Data.data?.id : 0,
					incidentSection4Id:
						'data' in createSection4Data ? createSection4Data.data?.id : 0,
					incidentSection5Id:
						'data' in createSection5Data ? createSection5Data.data?.id : 0,
				};

				dispatch(updateIncidentSectionId(updateIncidentStatePayload));
			} else {
				await updateIncidentSection1(payload).catch((err) => {
					throw new Error(err);
				});
			}

			if (!isSaveButton) {
				setSection('section2');
				setTabStates({
					...tabStates,
					section1: true,
				});
			}
		} else {
			//replace with error popup
			console.log('Please ensure you fill out all the required fields');
		}
	};

	return (
		<>
			{isLoading ? (
				<>
					<p>Loading..</p>
				</>
			) : (
				<>
					<Heading type="h2">Incident Description</Heading>
					<form>
						<div className="flex gap-8 flex-wrap">
							<div className="basis-1/5 grow">
								<DateInput
									placeholder="Select Date"
									onChange={(value: Date) =>
										handleSection1Update('incident_date', value)
									}
									className="mb-10"
									label="Incident Date"
									required={true}
									iconClassName="text-black"
									selected={
										IncidentState.isNewIncident ? null : section1.incident_date
									}
									wrapperClassName="react-datepicker-margin-0"
								/>
							</div>
							<div className="basis-1/5 grow">
								<Input
									type="time"
									onChange={(value: string) =>
										handleSection1Update('incident_time', value)
									}
									value={section1.incident_time}
									className="mb-10"
									required={true}
									label="Incident time"
									Icon={ClockIcon}
									iconClassName="text-black"
									iconPosition="trailing"
									placeholder="Select Time"
								/>
							</div>
							<div className="basis-1/5 grow">
								<Select
									label="Branch"
									options={section1.branch_options}
									placeholder="Select Branch"
									onChange={(value: string) =>
										handleSection1Update('branch', value)
									}
									className="mb-10"
									required={true}
									selected={section1.branch}
								/>
							</div>
							<div className="basis-1/5 grow">
								<DateInput
									placeholder="Select Date"
									onChange={(value: Date) =>
										handleSection1Update('reported_date', value)
									}
									className="mb-10"
									label="Reported Date"
									required={true}
									iconClassName="text-black"
									selected={
										IncidentState.isNewIncident ? null : section1.reported_date
									}
									wrapperClassName="react-datepicker-margin-0"
								/>
							</div>
						</div>
						<div>
							<TextareaInput
								value={section1.description}
								label="Incident Description"
								required={true}
								rows={6}
								cols={37}
								placeholder="Description"
								resize={false}
								onChange={(value: string) =>
									handleSection1Update('description', value)
								}
							/>
							<Text className="py-4 text-xs">
								Clearly state what happened in the incident, including the
								outcome and sequence of events leading up to the incident.
							</Text>
						</div>
						<div className="flex gap-8 flex-wrap mt-4">
							<div className="basis-1/4 grow">
								<Input
									type="text"
									onChange={(value: string) =>
										handleSection1Update('client', value)
									}
									value={section1.client}
									className=""
									required={true}
									label="Client Name"
									placeholder="Name"
								/>
							</div>
							<div className="basis-1/4 grow">
								<TextareaInput
									value={section1.location}
									label="Exact Location"
									required={true}
									rows={6}
									cols={37}
									placeholder="Description"
									resize={false}
									onChange={(value: string) =>
										handleSection1Update('location', value)
									}
								/>
								<Text className="py-4 text-xs">
									Location where the incident happened. (Location, Address or
									area)
								</Text>
							</div>
							<div className="basis-1/4 grow">
								<TextareaInput
									onChange={(value: string) =>
										handleSection1Update('jobDetails', value)
									}
									value={section1.jobDetails}
									label="Job Details"
									required={true}
									rows={6}
									cols={37}
									placeholder="Description"
									resize={false}
								/>
								<Text className="py-4 text-xs">
									Clearly state Client/Job details
								</Text>
							</div>
						</div>
						<div className="h-0 border-t border-primary-200 w-full my-5" />
						<ToggleButton
							toggle={toggle}
							setToggle={setToggle}
							label="Would you like to enter details of an MVI or MVI3 incident? *"
						/>
						{toggle && (
							<div className="flex gap-8 flex-wrap my-6">
								<Input
									type="text"
									onChange={(value: string) =>
										handleSection1Update('equipmentNumber', value)
									}
									value={section1.equipmentNumber}
									className="basis-1/5 grow"
									label="Altus equipment number"
								/>
								<Input
									type="text"
									onChange={(value: string) =>
										handleSection1Update('ticketNumber', value)
									}
									value={section1.ticketNumber}
									className="basis-1/5 grow"
									label="Ticket Number"
								/>
								<Input
									type="text"
									onChange={(value: string) =>
										handleSection1Update('personReported', value)
									}
									value={section1.personReported}
									className="basis-1/5 grow"
									label="Name of Person Reported"
								/>
								<Input
									type="text"
									onChange={(value: string) =>
										handleSection1Update('project', value)
									}
									value={section1.project}
									className="basis-1/5 grow"
									label="Project"
								/>
							</div>
						)}

						<div className="h-0 border-t border-primary-200 w-full my-5" />

						<div className="flex">
							<Button
								onClick={() => handleCreateIncidentSection1(true)}
								type="secondary"
								className="font-bold mr-4"
							>
								Save
							</Button>
							<Button
								onClick={() => handleCreateIncidentSection1(false)}
								className="font-bold"
							>
								Continue to Person Involved
								<ChevronRightIcon height={18} width={32} />
							</Button>
						</div>
					</form>
				</>
			)}
		</>
	);
};

export default IncidentSection1;
