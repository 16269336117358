import Heading from 'components/atoms/Heading';
import Radio from 'components/atoms/Radio';
import Text from 'components/atoms/Text';
import StatusButton from 'components/atoms/StatusButton';
import React, { useEffect, useState } from 'react';
import {
	IQueryReportSection,
	ITabState,
	IVehicleIncidentSection4,
} from 'types/VehicleIncidents';
import FormCheckBoxGroup from 'components/molecules/FormCheckBoxGroup';
import {
	useGetIncidentSection4Mutation,
	useUpdateIncidentSection4Mutation,
	useUpdateIncidentSection4FileUploadMutation,
	useDeleteIncidentSection4FileUploadMutation,
} from 'redux/api/vehicleIncidents';
import ToggleButton from 'components/atoms/ToggleButton';
import Input from 'components/atoms/Input';
import { IOption, section4RadioButtons } from 'types/VehicleIncidents';
import Button from 'components/atoms/Button';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import SlideDown from 'components/atoms/SlideDown';
import FileInputDragDrop from 'components/atoms/FileInputDragDrop';

export interface IIncidentSection4 {
	setSection: React.Dispatch<React.SetStateAction<IQueryReportSection>>;
	sectionId: number | null;
	tabStates: ITabState;
	setTabStates: React.Dispatch<React.SetStateAction<ITabState>>;
	previousComponent: string;
	setPreviousComponent: React.Dispatch<React.SetStateAction<string>>;
}

const initialState = {
	severityRating: '1',
	ticketNumber: '',
	reportedTo: '',
	fileName: '',
	coachingEvidence: '',
	incidentCategory: [
		{
			id: 1,
			title: '',
			checked: false,
			description: '',
		},
	],
	lifeSavingRules: [
		{
			id: 1,
			title: '',
			checked: false,
			description: '',
		},
	],
	codeOfConduct: [
		{
			id: 1,
			title: '',
			checked: false,
		},
	],
	coaching_evidence_file: {
		id: 0,
		name: '',
	},
};

const IncidentSection4: React.FC<IIncidentSection4> = ({
	setSection,
	sectionId,
	setTabStates,
	tabStates,
	previousComponent,
	setPreviousComponent,
}) => {
	const [getIncidentSection4, { data, isSuccess }] =
		useGetIncidentSection4Mutation();
	const [updateIncidentSection4] = useUpdateIncidentSection4Mutation();
	const [updateIncidentSection4FileUpload] =
		useUpdateIncidentSection4FileUploadMutation();
	const [deleteIncidentSection4FileUpload] =
		useDeleteIncidentSection4FileUploadMutation();
	const [isNotifiableIncident, setIsNotifiableIncident] = useState(false);
	const [isLifeSavingBreach, setIsLifeSavingBreach] = useState(false);
	const [file, setFile] = useState<File[]>([]);
	const [isCodeOfConductBreach, setIsCodeOfConductBreach] = useState(false);
	const [section4, setSection4] = useState(initialState);
	const [errorState, setErrorState] = useState({
		incidentCategory: false,
		lifeSavingRules: false,
		codeOfConduct: false,
		ticketNumber: false,
		reportedTo: false,
	});

	let incidentCategoryChecked = false;
	let lifeSavingChecked = false;
	let codeOfConductChecked = false;

	const backSection = () => {
		if (previousComponent === 'section2') {
			setSection('section2');
			setTabStates({
				...tabStates,
				section2: false,
				section3: false,
			});
		} else {
			setSection('section3');
			setTabStates({
				...tabStates,
				section3: false,
			});
		}
	};

	useEffect(() => {
		if (sectionId !== null) {
			getIncidentSection4(sectionId);
		}
	}, [getIncidentSection4, sectionId]);

	useEffect(() => {
		if (isSuccess && data) {
			const {
				severity_rating,
				ticket_number,
				reported_to,
				notifiable_incident,
				life_saving_rule_breach,
				code_of_conduct_breach,
				incident_category,
				life_saving_rules,
				code_of_conduct,
				coaching_evidence,
				coaching_evidence_file,
			} = data.data;

			let selectedRadioButton = section4.severityRating;
			section4RadioButtons.forEach((item) => {
				if (item.title === severity_rating) {
					selectedRadioButton = item.id;
				}
			});

			setSection4({
				...section4,
				severityRating: selectedRadioButton,
				incidentCategory: incident_category,
				lifeSavingRules: life_saving_rules,
				codeOfConduct: code_of_conduct,
				ticketNumber: ticket_number || '',
				reportedTo: reported_to || '',
				coachingEvidence: coaching_evidence || '',
				fileName: coaching_evidence_file?.name || '',
				coaching_evidence_file: coaching_evidence_file || { id: 0, name: '' },
			});
			setIsNotifiableIncident(notifiable_incident);
			setIsLifeSavingBreach(life_saving_rule_breach);
			setIsCodeOfConductBreach(code_of_conduct_breach);
		}
		//removes dependency for "section 4"
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleSection4Update = (
		property: string,
		value: IOption[] | string
	) => {
		setSection4({
			...section4,
			[property]: value,
		});
	};

	const handleFileUpdate = (file: File[]) => {
		setFile(file);
	};

	const handleCreateIncidentSection4 = async () => {
		incidentCategoryChecked = section4.incidentCategory.every((item) => {
			return item.checked === false;
		});
		if (incidentCategoryChecked) {
			setErrorState({
				...errorState,
				incidentCategory: incidentCategoryChecked,
			});
			return;
		}

		if (isLifeSavingBreach) {
			lifeSavingChecked = section4.lifeSavingRules.every((item) => {
				return item.checked === false;
			});
			if (lifeSavingChecked) {
				setErrorState({
					...errorState,
					lifeSavingRules: lifeSavingChecked,
				});
				return;
			}
		}

		if (isCodeOfConductBreach) {
			codeOfConductChecked = section4.codeOfConduct.every((item) => {
				return item.checked === false;
			});
			if (codeOfConductChecked) {
				setErrorState({
					...errorState,
					codeOfConduct: codeOfConductChecked,
				});
				return;
			}
		}

		if (
			isNotifiableIncident &&
			(section4.ticketNumber === '' || section4.reportedTo === '')
		) {
			setErrorState({
				...errorState,
				ticketNumber: section4.ticketNumber === '',
				reportedTo: section4.reportedTo === '',
			});
			return;
		}

		if (!isNotifiableIncident) {
			setSection4({
				...section4,
				ticketNumber: '',
				reportedTo: '',
			});
		}

		let convertRadioLabel = section4.severityRating;
		section4RadioButtons.forEach((item) => {
			if (item.id === section4.severityRating) {
				convertRadioLabel = item.title;
			}
		});

		if (!sectionId) {
			return;
		}

		const payload: IVehicleIncidentSection4 = {
			id: sectionId,
			severity_rating: convertRadioLabel,
			incident_category: section4.incidentCategory,
			notifiable_incident: isNotifiableIncident,
			ticket_number: section4.ticketNumber,
			reported_to: section4.reportedTo,
			life_saving_rule_breach: isLifeSavingBreach,
			life_saving_rules: section4.lifeSavingRules,
			coaching_evidence: section4.coachingEvidence,
			code_of_conduct_breach: isCodeOfConductBreach,
			code_of_conduct: section4.codeOfConduct,
		};

		if (file.length === 1) {
			if (section4.coaching_evidence_file.id !== 0) {
				//deletes file if one exists so it can then be replaced
				await deleteIncidentSection4FileUpload(
					section4.coaching_evidence_file['id']
				).catch((err) => {
					throw new Error(err);
				});
			}

			// Create form data for s3
			let data = new FormData();
			data.append('files', file[0]);
			data.append('data', JSON.stringify(payload));
			data.append('refId', '1');
			data.append('ref', 'api::incident-section-4.incident-section-4');
			data.append('field', 'coaching_evidence_file');

			await updateIncidentSection4FileUpload({ data }).catch((err) => {
				throw new Error(err);
			});
		}

		await updateIncidentSection4(payload).catch((err) => {
			throw new Error(err);
		});
		setSection('section5');
		setPreviousComponent('section4');
		setTabStates({
			...tabStates,
			section4: true,
		});
	};
	return (
		<>
			<Heading type="h2">Incident Analysis</Heading>
			<Heading type="h3">Severity</Heading>
			<div className="grid gap-3 grid-col grid-cols-8">
				<Text className="col-span-2">Rating</Text>
				<Text>Close Out</Text>
				<Text>Description</Text>
			</div>
			<div className="h-0 border-t border-primary-200 w-full sm:w-1/2 my-5" />
			<div className="grid gap-3 grid-col grid-cols-8">
				<Radio
					options={section4RadioButtons}
					onChange={(value: string) =>
						handleSection4Update('severityRating', value)
					}
					defaultId={section4.severityRating}
					className="mt-2 mb-2"
					classNameWrapper="gap-4"
				/>
				<div className="flex flex-col flex-1 justify-around">
					<StatusButton
						status="low"
						className="bg-transparent font-bold"
					></StatusButton>
					<StatusButton
						status="medium"
						className="bg-transparent font-bold"
					></StatusButton>
					<StatusButton
						status="high"
						className="bg-transparent font-bold"
					></StatusButton>
					<StatusButton
						status="severe"
						className="bg-transparent font-bold"
					></StatusButton>
					<StatusButton
						status="extreme"
						className="bg-transparent font-bold"
					></StatusButton>
				</div>
				<div className="flex flex-col flex-1 justify-around">
					<Text>2 days</Text>
					<Text>5 days</Text>
					<Text>10 days</Text>
					<Text>Immediate</Text>
					<Text>Immediate</Text>
				</div>
				<div className="col-span-3 flex flex-col flex-1 justify-around">
					<Text>Minor injury (NOT/FAI) or very minor damage.</Text>
					<Text>MTI, Moderate damage</Text>
					<Text>LTI or significant same. Escalate to Safety GM.</Text>
					<Text>Fatality, vehicle write offs. Escalate to Safety GM.</Text>
					<Text>Death</Text>
				</div>
			</div>
			<div className="h-0 border-t border-primary-200 w-full my-5" />
			<Heading type="h2">Incident Category *</Heading>
			<FormCheckBoxGroup
				checkBoxName="incidentCategory"
				checkBoxGroupData={section4.incidentCategory}
				onHandleChange={handleSection4Update}
				descriptionDirection="flex-col"
				errorState={errorState.incidentCategory}
				gridColumns={'5'}
			/>
			<div className="h-0 border-t border-primary-200 w-full my-5" />
			<ToggleButton
				toggle={isNotifiableIncident}
				setToggle={setIsNotifiableIncident}
				label="Notifiable Incident? *"
			/>
			<Text className="text-sm">
				Notifiable Incidents are those serious incidents which must be reported
				to the State Safety Regulator and include death, serious
				injuries/illnesses (inc injuries requiring hospital admission, head
				injuries, serious lacerations, spinal injuries, serious burns etc) and
				dangerous incidents (including serious near misses) such as major
				vehicle incidents, major enviromental incidents etc.
			</Text>
			<SlideDown
				open={isNotifiableIncident}
				className={`${isNotifiableIncident ? 'my-2' : 'hidden'}`}
			>
				<div className="flex flex-row">
					<Input
						type="text"
						value={section4.ticketNumber}
						label="Ticket Number"
						required={true}
						className="w-[350px]"
						onChange={(value: string) =>
							handleSection4Update('ticketNumber', value)
						}
						errorMessage={
							errorState.ticketNumber ? 'Please enter the position' : ''
						}
					/>
					<Input
						type="text"
						value={section4.reportedTo}
						label="Reported to"
						required={true}
						className="w-[350px] ml-3"
						onChange={(value: string) =>
							handleSection4Update('reportedTo', value)
						}
						errorMessage={
							errorState.reportedTo ? 'Please enter the position' : ''
						}
					/>
				</div>
			</SlideDown>
			<div className="h-0 border-t border-primary-200 w-full my-5" />
			<Heading type={'h2'}>Life Saving Rules</Heading>
			<ToggleButton
				toggle={isLifeSavingBreach}
				setToggle={setIsLifeSavingBreach}
				label="Was this a breach of Life Saving Rules?"
				required={true}
			/>
			<SlideDown
				open={isLifeSavingBreach}
				className={`${isLifeSavingBreach ? 'my-2' : 'hidden'}`}
			>
				<Text type="bold" className="mb-3">
					Did this result in termination of employment
				</Text>
				<FormCheckBoxGroup
					checkBoxName="lifeSavingRules"
					checkBoxGroupData={section4.lifeSavingRules}
					onHandleChange={handleSection4Update}
					descriptionClassName="ml-2"
					descriptionDirection="flex-row"
					errorState={errorState.lifeSavingRules}
				/>
			</SlideDown>
			{!isLifeSavingBreach && (
				<div className="flex flex-row">
					<Input
						type="text"
						value={section4.coachingEvidence}
						label="Provide evidence of coaching"
						className="w-2/3 ml-3"
						placeholder="Description"
						onChange={(value: string) =>
							handleSection4Update('coachingEvidence', value)
						}
					/>
					<FileInputDragDrop
						label="Attach a file"
						onChange={handleFileUpdate}
						defaultFileName={section4.fileName}
						clearIcon={section4.fileName !== '' ? true : false}
					/>
				</div>
			)}
			<Heading type={'h2'}>Code of Conduct</Heading>
			<ToggleButton
				toggle={isCodeOfConductBreach}
				setToggle={setIsCodeOfConductBreach}
				label="Was this a breach of the Code of Conduct?"
			/>
			{isCodeOfConductBreach && (
				<>
					<Text type="bold">Select breaches</Text>
					<FormCheckBoxGroup
						checkBoxName="codeOfConduct"
						checkBoxGroupData={section4.codeOfConduct}
						onHandleChange={handleSection4Update}
						errorState={errorState.codeOfConduct}
						gridColumns="6"
					/>
				</>
			)}
			<div className="h-0 border-t border-primary-200 w-full my-5" />
			<div className="flex">
				<Button
					onClick={backSection}
					type="secondary"
					className="font-bold mr-4"
				>
					<ChevronLeftIcon height={18} width={24} />
					Back
				</Button>
				<Button onClick={handleCreateIncidentSection4} className="font-bold">
					Continue to Incident Investigation
					<ChevronRightIcon height={18} width={32} />
				</Button>
			</div>
		</>
	);
};

export default IncidentSection4;
