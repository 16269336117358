import { PencilAltIcon, AnnotationIcon } from '@heroicons/react/outline';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import React from 'react';
import { useAppDispatch } from 'redux/hooks';
import {
	openCommentModal,
	openIncidentModal,
} from 'redux/slices/vehicleIncidentsSlice';
import { IVehicleIncident } from 'types/VehicleIncidents';

export interface IIncidentCard {
	incident: IVehicleIncident;
}

const IncidentCard: React.FC<IIncidentCard> = ({ incident }) => {
	const dispatch = useAppDispatch();

	const handleOpenComments = () => {
		const payload = {
			commentIncidentId: incident.id,
			incidentBranchId: incident?.branch?.id,
		};
		dispatch(openCommentModal(payload));
	};

	const handleOpenIncidentReport = () => {
		try {
			const payload = {
				incidentReportId: incident.id,
				incidentSection1Id: incident?.incident_section_1?.id,
				incidentSection2Id: incident?.incident_section_2?.id,
				incidentSection3Id: incident?.incident_section_3?.id,
				incidentSection4Id: incident?.incident_section_4?.id,
				incidentSection5Id: incident?.incident_section_5?.id,
			};
			dispatch(openIncidentModal(payload));
		} catch (error) {
			if (error instanceof Error) {
				throw new Error(error.message);
			}
		}
	};

	return (
		<div className="w-full px-10 pb-10 pt-8 border-t border-primary">
			<div className="flex">
				<div className="flex-col w-1/2 xl:w-3/4">
					<Heading type="h2" className="mb-6">
						Overview
					</Heading>
					<div className="flex pr-8 lg:pr-12 2xl:pr-32 justify-between space-x-8 lg:space-x-12 2xl:space-x-32">
						<div className="min-w-[200px]">
							<Heading type="h3" className="mb-6">
								Description
							</Heading>
							<Text className="max-w-lg mb-10 text-sm">
								{incident.description}
							</Text>
							<div className="flex w-[350px]">
								<Text
									className="flex basis-1/2 text-sm mb-2 w-[200px]"
									type="bold"
								>
									Incident Category
								</Text>
								<Text className="flex basis-1/2 text-sm mb-2">CO</Text>
							</div>
							<div className="h-0 border-t-[1px] border-primary-200 w-full my-5" />
							<div className="flex w-[350px]">
								<Text
									className="flex basis-1/2 mb-6 text-sm w-[200px]"
									type="bold"
								>
									Client
								</Text>
								<Text className="flex basis-1/2 mb-6 text-sm">
									{incident.client}
								</Text>
							</div>
						</div>

						<div className="hidden xl:flex xl:flex-col">
							<div className="mb-4 space-y-2">
								<Text type="bold">Created:</Text>
								<Text className="text-sm">
									{new Date(incident.submitted_at).toLocaleDateString('en-AU')}
								</Text>
							</div>
							<div className="mb-4 space-y-2">
								<Text type="bold">Last Action By:</Text>
								<Text className="text-sm">N. Flanders</Text>
							</div>
						</div>
						<div className="hidden xl:flex xl:flex-col">
							<div className="mb-4 space-y-2">
								<Text type="bold">Escalation:</Text>
								<div>
									<Text className="text-sm">Escalation to BM: 26/07/21</Text>
									<Text className="text-sm">Escalation to RM: 02/08/21</Text>
								</div>
							</div>
							<div className="mb-4 space-y-2">
								<Text type="bold">Assigned to:</Text>
								<Text className="text-sm">Branch: {incident.branch?.name}</Text>
								<Text className="text-sm">Escalation to RM: 02/08/21</Text>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-1 w-1/2 xl:w-1/4 justify-end">
					<div className="max-w-sm w-full max-h-14">
						<div className="flex flex-row justify-around items-center">
							<Button
								className="mr-5 font-bold xl:mb-0"
								onClick={handleOpenIncidentReport}
								size="lg"
							>
								<PencilAltIcon className="h-5 w-5 mr-1" />
								Continue to Report
							</Button>
							<Button
								type="quaternary"
								className="mr-5 font-bold"
								onClick={handleOpenComments}
								size="lg"
							>
								<AnnotationIcon className="h-5 w-5 mr-1" />
								Comments
							</Button>
						</div>
					</div>
				</div>
			</div>
			{/*tidy up component for responsive desgin once data coming from API */}
			<div className="flex justify-between mt-8 w-full xl:hidden">
				<div className="mb-4 space-y-2">
					<Text type="bold">Created:</Text>
					<Text className="text-sm">
						{new Date(incident.submitted_at).toLocaleDateString('en-AU')}
					</Text>
				</div>
				<div className="mb-4 space-y-2">
					<Text type="bold">Last Action By:</Text>
					<Text className="text-sm">N. Flanders</Text>
				</div>
				<div className="mb-4 space-y-2">
					<Text type="bold">Escalation:</Text>
					<div>
						<Text className="text-sm">Escalation to BM: 26/07/21</Text>
						<Text className="text-sm">Escalation to RM: 02/08/21</Text>
					</div>
				</div>
				<div className="mb-4 space-y-2">
					<Text type="bold">Assigned to:</Text>
					<Text className="text-sm">Branch: {incident.branch?.name}</Text>
				</div>
			</div>
		</div>
	);
};

export default IncidentCard;
