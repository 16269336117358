import { useEffect, useState } from 'react';
import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import { useParams } from 'react-router-dom';
import {
	useGetSurveySubmissionMutation,
	useSubmitSurveySubmissionMutation,
} from 'redux/api/surveys';
import AltusLogo from 'assets/images/AltusLogo.svg';
import Button from 'components/atoms/Button';
import SurveySubmissionQuestion from 'components/molecules/SurveySubmissionQuestion';
import SurveySubmissionNotification from 'components/molecules/SurveySubmissionNotification';

const SurveysSubmission = () => {
	const { uid } = useParams();
	const [getSurveySubmission, { data, isSuccess, isLoading }] =
		useGetSurveySubmissionMutation();
	const [submitSurveySubmission, { isSuccess: isSubmitSuccess }] =
		useSubmitSurveySubmissionMutation();
	const [answers, setAnswers] = useState<string[]>([]);

	useEffect(() => {
		if (data && answers.length === 0) {
			const initialAnswers = data.data.survey.questions.map((question) => {
				if (question.type === 'questions.true-false') {
					return 'false';
				} else if (question.type === 'questions.multi-choice') {
					return question.options[0].option;
				}
				return '';
			});
			setAnswers(initialAnswers);
		}
	}, [data, answers]);

	useEffect(() => {
		if (uid) {
			getSurveySubmission(uid);
		}
	}, [getSurveySubmission, uid]);

	const handleChangeAnswers = (answer: string, index: number) => {
		const newAnswers: string[] = [...answers];
		newAnswers[index] = answer;
		setAnswers(newAnswers);
	};

	const handleSubmitSurveySubmission = () => {
		if (!data || !uid) {
			return null;
		}

		const mappedAnswers = data.data.survey.questions.map((question, index) => {
			return {
				questionId: question.id,
				answer: answers[index],
			};
		});

		submitSurveySubmission({
			uid,
			body: {
				answers: mappedAnswers,
			},
		});
	};

	if (isLoading) {
		return <SurveySubmissionNotification notification="Loading..." />;
	} else if (!data || !isSuccess) {
		return (
			<SurveySubmissionNotification notification="This page is not available!" />
		);
	} else if (isSubmitSuccess) {
		return (
			<SurveySubmissionNotification notification="Thank you for filling out the Survey!" />
		);
	} else if (data.data.survey_status !== 'open') {
		return (
			<SurveySubmissionNotification notification="This survey is no longer available!" />
		);
	}

	return (
		<main className="flex flex-col grow items-center justify-start h-screen">
			<div className="flex flex-col items-start bg-white w-full lg:w-4/6 max-w-screen-md m-4 p-4 drop-shadow-lg rounded">
				<div className="flex items-center justify-center w-full">
					<img
						className="h-[62px] w-auto m-4"
						src={AltusLogo}
						alt="AltusLogo"
					/>
					<Heading type="h1">{data.data.survey.name}</Heading>
				</div>
				<hr className="w-full bg-primary p-1 my-1" />
				{data.data.survey.questions.map((question, index) => (
					<SurveySubmissionQuestion
						num={index + 1}
						question={question.question}
						type={question.type}
						options={question.options.map((item) => item.option)}
						onChange={(answer: string) => handleChangeAnswers(answer, index)}
					/>
				))}
				<div className="flex items-center justify-center w-full mt-2">
					<Button type="primary" onClick={handleSubmitSurveySubmission}>
						<Text className="text-lg m-1" type="bold">
							Submit
						</Text>
					</Button>
				</div>
			</div>
		</main>
	);
};

export default SurveysSubmission;
