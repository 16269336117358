import React, { useEffect, useState } from 'react';
import Heading from 'components/atoms/Heading';
import Radio from 'components/atoms/Radio';
import Text from 'components/atoms/Text';
import ToggleButton from 'components/atoms/ToggleButton';
import Select from 'components/atoms/Select';
import TextareaInput from 'components/atoms/TextareaInput';
import DateInput from 'components/atoms/DateInput';
import Button from 'components/atoms/Button';
import SlideDown from 'components/atoms/SlideDown';
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	ClockIcon,
} from '@heroicons/react/outline';
import {
	IOption,
	IQueryReportSection,
	ITabState,
	IVehicleIncidentSection5,
	section5PercentageRadioButtons,
	section5PortionRadioButtons,
} from 'types/VehicleIncidents';
import Divider from 'components/atoms/Divider';
import Input from 'components/atoms/Input';
import FormCheckBoxGroup from 'components/molecules/FormCheckBoxGroup';
import {
	useGetIncidentSection5Mutation,
	useUpdateIncidentSection5Mutation,
} from 'redux/api/vehicleIncidents';
import './incidentSection5.module.css';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { closeIncidentModal } from 'redux/slices/vehicleIncidentsSlice';

export interface IIncidentSection5 {
	setSection: React.Dispatch<React.SetStateAction<IQueryReportSection>>;
	sectionId: number | null;
	tabStates: ITabState;
	setTabStates: React.Dispatch<React.SetStateAction<ITabState>>;
	previousComponent: string;
}

const initialState = {
	percentageShiftWorked: '',
	portionOfShiftWorked: '',
	shiftStartTime: '',
	organisationalFactorsInput: '',
	organisationalFactors2Input: '',
	individualFactorsInput: '',
	absentDefenseInput: '',
	actionTextInput: '',
	actionTakenInput: '',
	completedDate: new Date(),
	investigator: '',
	assignee: '',
	hierachyControl: [
		{
			id: 1,
			title: '',
			checked: false,
		},
	],
	users: [
		{
			id: '1',
			label: '',
		},
	],
};

const initialErrorState = {
	shift_start_time: false,
	hierachyControl: false,
	investigator: false,
	shiftStartTime: false,
	actionInput: false,
	assignedTo: false,
	actionTaken: false,
};

const IncidentSection5: React.FC<IIncidentSection5> = ({
	sectionId,
	setSection,
	setTabStates,
	tabStates,
	previousComponent,
}) => {
	const [getIncidentSection5, { data, isSuccess }] =
		useGetIncidentSection5Mutation();
	const [updateIncidentSection5] = useUpdateIncidentSection5Mutation();
	const [isInvestigation, setIsInvestigation] = useState(true);
	const [isAction, setIsAction] = useState(true);
	const [isCompleted, setIsCompleted] = useState(true);
	const [section5, setSection5] = useState(initialState);
	const [errorState, setErrorState] = useState(initialErrorState);
	const incidentSection1Id = useAppSelector(
		(state) => state.vehicleIncident.incidentSection1Id
	);
	const incidentReportId = useAppSelector(
		(state) => state.vehicleIncident.incidentReportId
	);
	const dispatch = useAppDispatch();

	//formats to YYYY-MM-DD
	const formatMyDate = (value: string | Date) => {
		const date = new Date(value);
		const formattedDate = [
			date.getFullYear(),
			`0${date.getMonth() + 1}`.slice(-2),
			`0${date.getDate()}`.slice(-2),
		].join('-');
		return formattedDate;
	};

	const backSection = () => {
		if (previousComponent === 'section3') {
			setSection('section3');
			setTabStates({
				...tabStates,
				section3: false,
				section4: false,
			});
		} else {
			setSection('section4');
			setTabStates({
				...tabStates,
				section4: false,
			});
		}
	};

	useEffect(() => {
		if (sectionId !== null) {
			getIncidentSection5(sectionId);
		}
	}, [getIncidentSection5, sectionId]);

	useEffect(() => {
		if (isSuccess && data) {
			const {
				portion_of_shift_worked,
				percentage_of_shift_worked,
				incident_investigation_required,
				shift_start_time,
				organisational_factors,
				organisational_factors_two,
				individual_factors,
				absent_failed_defense,
				add_action_required,
				action_description,
				completion_date,
				action_taken,
				completed,
				hierachy_of_control,
				assignee,
				users,
				investigator,
			} = data.data;

			let selectedPercentageRadioButton = percentage_of_shift_worked;
			section5PercentageRadioButtons.forEach((item) => {
				if (item.title === percentage_of_shift_worked) {
					selectedPercentageRadioButton = item.id;
				}
			});

			let selectedPortionRadioButton = portion_of_shift_worked;
			section5PortionRadioButtons.forEach((item) => {
				if (item.title === portion_of_shift_worked) {
					selectedPortionRadioButton = item.id;
				}
			});

			const createCompletionDate = new Date(completion_date);

			setSection5({
				...section5,
				percentageShiftWorked: selectedPercentageRadioButton,
				portionOfShiftWorked: selectedPortionRadioButton,
				shiftStartTime: shift_start_time,
				organisationalFactorsInput: organisational_factors || '',
				organisationalFactors2Input: organisational_factors_two || '',
				individualFactorsInput: individual_factors || '',
				absentDefenseInput: absent_failed_defense || '',
				actionTextInput: action_description,
				completedDate: createCompletionDate,
				hierachyControl: hierachy_of_control,
				actionTakenInput: action_taken,
				users,
				assignee,
				investigator,
			});

			setIsInvestigation(incident_investigation_required);
			setIsAction(add_action_required);
			setIsCompleted(completed);
		}
		//removes dependency for "section 5"
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleSection5Update = (
		property: string,
		value: string | Date | IOption[]
	) => {
		setSection5({
			...section5,
			[property]: value,
		});
	};

	const handleCreateIncidentSection5 = async () => {
		const {
			investigator,
			hierachyControl,
			actionTextInput,
			assignee,
			actionTakenInput,
			shiftStartTime,
		} = section5;

		const hierachyOfControlChecked = hierachyControl.every((item) => {
			return item.checked === false;
		});

		const isError = [
			isInvestigation && investigator === '',
			shiftStartTime === '',
			isAction &&
				(actionTextInput === '' ||
					assignee === '' ||
					actionTakenInput === '' ||
					hierachyOfControlChecked),
		].every(Boolean);

		if (isError) {
			setErrorState({
				...errorState,
				investigator: investigator === '',
				actionInput: actionTextInput === '',
				actionTaken: actionTakenInput === '',
				hierachyControl: hierachyOfControlChecked,
				assignedTo: assignee === '',
				shiftStartTime: shiftStartTime === '',
			});
			return;
		}

		let percentageOfShiftWorked = section5.percentageShiftWorked;
		let portionShiftWorked = section5.portionOfShiftWorked;
		section5PortionRadioButtons.forEach((item) => {
			if (item.id === section5.portionOfShiftWorked) {
				portionShiftWorked = item.title;
			}
		});
		section5PercentageRadioButtons.forEach((item) => {
			if (item.id === section5.percentageShiftWorked) {
				percentageOfShiftWorked = item.title;
			}
		});

		const formatShiftStartTime =
			section5.shiftStartTime.length < 6
				? `${section5.shiftStartTime}:00.000`
				: section5.shiftStartTime;

		const formatCompletedDate = formatMyDate(section5.completedDate);

		if (!sectionId) {
			return;
		}

		const payload: IVehicleIncidentSection5 = {
			id: sectionId,
			portion_of_shift_worked: portionShiftWorked,
			percentage_of_shift_worked: percentageOfShiftWorked,
			organisational_factors: section5.organisationalFactorsInput,
			organisational_factors_two: section5.organisationalFactors2Input,
			individual_factors: section5.individualFactorsInput,
			absent_failed_defense: section5.absentDefenseInput,
			add_action_required: isAction,
			incident_investigation_required: isInvestigation,
			completed: isCompleted,
			investigator: section5.investigator,
			assignee: section5.assignee,
			hierachy_of_control: section5.hierachyControl,
			users: section5.users,
			shift_start_time: formatShiftStartTime,
			action_description: section5.actionTextInput,
			action_taken: section5.actionTakenInput,
			completion_date: formatCompletedDate,
			section1Id: incidentSection1Id,
			incidentReportId,
		};

		await updateIncidentSection5(payload).catch((err) => {
			throw new Error(err);
		});

		dispatch(closeIncidentModal());
	};

	return (
		<>
			<Heading type="h2">Incident Investigation</Heading>
			<ToggleButton
				toggle={isInvestigation}
				setToggle={setIsInvestigation}
				label="Incident Investigation Required"
			/>
			{isInvestigation && (
				<div>
					<Select
						label="Investigator"
						options={section5.users}
						placeholder="Select"
						onChange={(value: string) =>
							handleSection5Update('investigator', value)
						}
						className="w-80 mb-10"
						required={true}
						selected={section5.investigator}
					/>
				</div>
			)}

			<Divider />
			<div className="flex flex-row">
				<Input
					type="time"
					onChange={(value: string) =>
						handleSection5Update('shiftStartTime', value)
					}
					value={section5.shiftStartTime}
					className="w-80"
					label="Shift Start Time"
					Icon={ClockIcon}
					iconClassName="text-black"
					iconPosition="trailing"
					placeholder="Select"
					errorMessage={
						errorState.shiftStartTime ? 'Please enter the shift start time' : ''
					}
				/>
				<div className="pl-16">
					<Text className="block text-base font-inter font-bold text-secondary">
						Portion of Shift Worked when Incident Occured *
					</Text>
					<Radio
						options={section5PercentageRadioButtons}
						onChange={(value: string) =>
							handleSection5Update('percentageShiftWorked', value)
						}
						defaultId={section5.percentageShiftWorked || '1'}
						radioDirection="flex-row"
						classNameWrapper="gap-4"
					/>
				</div>
			</div>
			<Divider />
			<Heading type="h3">
				What factors contributed to the incident occuring?
			</Heading>
			<div className="grid gap-8 grid-col grid-cols-2 xl:grid-cols-4 space-y-2 ml-1">
				<div>
					<Text className="text-sm py-2">Organisational Factors</Text>
					<Text className="text-xs" color="text-secondary-300">
						The underlying factors in the organisation that influenced and
						produced the conditions affecting the performance in the workplace.
						Examples: No SOP for a particular task, inadequate training, staff
						not properly inducted, no process to ensure appropriate resources on
						the job, failure of fatigue management process etc.
					</Text>
				</div>
				<TextareaInput
					value={section5.organisationalFactorsInput}
					rows={5}
					cols={35}
					placeholder="Description"
					resize={false}
					onChange={(value: string) =>
						handleSection5Update('organisationalFactorsInput', value)
					}
				/>
				<div>
					<Text className="text-sm py-2">Organisational Factors</Text>
					<Text className="text-xs" color="text-secondary-300">
						The local workplace factors. The conditions, circumstances or
						objects in the workplace that directly influenced human and
						equipment performance at the time or immediately prior to an
						incident. Examples: Extreme temperatures, poor light, wet weather,
						bend in road affecting visibility, no shoulder for placement of
						signs etc.
					</Text>
				</div>
				<TextareaInput
					value={section5.organisationalFactors2Input}
					rows={5}
					cols={35}
					placeholder="Description"
					resize={false}
					onChange={(value: string) =>
						handleSection5Update('organisationalFactors2Input', value)
					}
				/>
				<div>
					<Text className="text-sm py-2">Individual / Team Factors</Text>
					<Text className="text-xs" color="text-secondary-300">
						The actions, errors, or violations that led directly to the
						incident. Examples: Traffic Controller crossed live lane of traffic;
						Traffic Controller stopping traffic with no Stop Bat or not wearing
						PPE; Member of public on mobile phone driving through site; Machine
						operator not communicating plant movement etc.
					</Text>
				</div>
				<TextareaInput
					value={section5.individualFactorsInput}
					rows={5}
					cols={35}
					placeholder="Description"
					resize={false}
					onChange={(value: string) =>
						handleSection5Update('individualFactorsInput', value)
					}
				/>
				<div>
					<Text className="text-sm py-2">Absent / Failed Defenses</Text>
					<Text className="text-xs" color="text-secondary-300">
						The last minute measures which did not prevent the outcome of the
						incident or reduce its consequences. Examples: Hand brake not
						engaged (runaway vehicle); Vehicle protruding into live lane
						(Vehicle incident); TC not wearing steel capped boots (foot injury)
						etc.
					</Text>
				</div>
				<TextareaInput
					value={section5.absentDefenseInput}
					rows={5}
					cols={35}
					placeholder="Description"
					resize={false}
					onChange={(value: string) =>
						handleSection5Update('absentDefenseInput', value)
					}
				/>
			</div>
			<Divider />
			<Heading type="h2">
				List control measures to be implemented to avoid this incident occuring
				again?
			</Heading>
			<ToggleButton
				toggle={isAction}
				setToggle={setIsAction}
				label="Add an action"
			/>
			<SlideDown open={isAction} className={`${isAction ? 'my-2' : 'hidden'}`}>
				<div className="flex flex-row">
					<div className="w-1/3">
						<Text className="block text-base font-inter font-bold text-secondary whitespace-nowrap">
							Portion of Shift Worked when Incident Occured *
						</Text>
						<Radio
							options={section5PortionRadioButtons}
							onChange={(value: string) =>
								handleSection5Update('portionOfShiftWorked', value)
							}
							defaultId={section5.portionOfShiftWorked || '1'}
							radioDirection="flex-row"
							classNameWrapper="gap-4"
						/>
					</div>
					<Input
						type="text"
						onChange={(value: string) =>
							handleSection5Update('actionTextInput', value)
						}
						value={section5.actionTextInput}
						className="w-full pl-6 mb-10"
						label="Action"
						required={true}
						errorMessage={
							errorState.actionInput ? 'Please enter the action' : ''
						}
					/>
				</div>
				<div className="flex flex-row gap-3">
					<div>
						<Select
							label="Assigned to"
							options={section5.users}
							placeholder="Select"
							onChange={(value: string) =>
								handleSection5Update('assignee', value)
							}
							className="w-72"
							required={true}
							selected={section5.assignee}
						/>
					</div>
					<div>
						<DateInput
							placeholder="Select Date"
							onChange={(value: Date) =>
								handleSection5Update('completedDate', value)
							}
							className="w-72 mb-10"
							label="Completion Date *"
							iconClassName="text-black"
							selected={section5.completedDate}
							wrapperClassName="react-datepicker-margin-0"
						/>
					</div>
					<div>
						<Text className="font-inter leading-[26px] font-bold tracking-[-1px] text-secondary pb-2">
							Hierachy of Control *
						</Text>
						<FormCheckBoxGroup
							checkBoxName="hierachyControl"
							checkBoxGroupData={section5.hierachyControl}
							onHandleChange={handleSection5Update}
							descriptionDirection="flex-row"
							gridColumns={'6'}
							errorState={errorState.hierachyControl}
						/>
					</div>
				</div>
				<div
					style={{
						marginTop: '0px',
					}}
				>
					<Input
						type="text"
						onChange={(value: string) =>
							handleSection5Update('actionTakenInput', value)
						}
						value={section5.actionTakenInput}
						className="w-full"
						label="Action Taken"
						required={true}
						errorMessage={
							errorState.actionTaken ? 'Please enter the action taken' : ''
						}
					/>
				</div>
				<ToggleButton
					toggle={isCompleted}
					setToggle={setIsCompleted}
					label="Completed"
				/>
			</SlideDown>

			<div className="flex">
				<Button
					onClick={backSection}
					type="secondary"
					className="font-bold mr-4"
				>
					<ChevronLeftIcon height={18} width={24} />
					Back
				</Button>
				<Button onClick={handleCreateIncidentSection5} className="font-bold">
					Create Report
					<ChevronRightIcon height={18} width={32} />
				</Button>
			</div>
		</>
	);
};

export default IncidentSection5;
