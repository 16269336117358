export type IQueryPeriod = 'today' | 'week' | 'month' | 'all';
export type IQueryReportSection =
	| 'section1'
	| 'section2'
	| 'section3'
	| 'section4'
	| 'section5';

export interface ITabState {
	section1: boolean;
	section2: boolean;
	section3: boolean;
	section4: boolean;
}
export interface IVehicleIncident {
	id: number;
	alloc8_id: string;
	type: 'incident';
	submitted_at: string;
	createdAt: string;
	updatedAt: string;
	status: 'open' | 'closed' | 'draft';
	severity: 'normal' | 'high';
	ticket_number: string;
	description: string;
	submitted_by: string;
	incident_section_1: {
		id: number;
	};
	incident_section_2: {
		id: number;
	};
	incident_section_3: {
		id: number;
	};
	incident_section_4: {
		id: number;
	};
	incident_section_5: {
		id: number;
	};
	client: string;
	branch: {
		id: string;
		name: string;
	};
	incidents: {
		question: string;
		answer: string;
	}[];
	managers: {
		id: string;
		first_name: string;
		last_name: string;
	}[];
	assignee?: {
		id: string;
		first_name: string;
		last_name: string;
	};
}

export interface ISortIncidentData {
	branch: IOption[];
	client: IOption[];
	severity: IOption[];
	status: IOption[];
}

export interface IVehicleCreateIncident {
	id: number;
	alloc8_id: null;
	client: null;
	description: null;
	severity: string;
	status: string;
	submitted_by: null;
	ticket_number: null;
}

export interface IVehicleIncidentUpdate {
	id: number;
	branch: number | null;
	ticket_number: string;
	client_name: string;
	description: string;
}

export interface IVehicleIncidentSection1 {
	id: number | null;
	createdAt?: string;
	incident_date: string;
	branch: string;
	reported_date: string;
	location?: string;
	incident_time: string;
	incident_description: string;
	client_name: string;
	job_details: string;
	mv_incident: boolean;
	equipment_number?: string;
	ticket_number?: string;
	name_of_person_reported?: string;
	project?: string;
	updatedAt?: string;
	branch_id?: string;
	branch_options: {
		id: string;
		label: string;
	}[];
}

export interface IVehicleCreateIncidentSection1 {
	id: number;
	client_name: string;
	equipment_number: string;
	incident_date: string;
	incident_description: string;
	incident_time: string;
	job_details: string;
	location: string;
	mv_incident: boolean;
	name_of_person_reported: string;
	project: string;
	branch: string;
	reported_date: string;
	ticket_number: string;
}

export interface IVehicleCreateIncidentSection3 {
	id: number;
	name: string;
	body_injured_other_description: null;
	incident_agency_other_description: null;
	incident_mechanism_other_description: null;
	injury_type_other_description: null;
	position: null;
	injuries: boolean;
	nuisance_report: boolean;
}

export interface IVehicleCreateIncidentSection3Payload {
	id: number;
	body_injured: {
		label: string;
		name: string;
		is_selected: boolean;
		description: string;
	}[];
	injury_type: {
		label: string;
		name: string;
		is_selected: boolean;
		description: string;
	}[];
	incident_mechanism: {
		label: string;
		name: string;
		is_selected: boolean;
		description: string;
	}[];
	incident_agency: {
		label: string;
		name: string;
		is_selected: boolean;
		description: string;
	}[];
}
export interface IVehicleCreateIncidentSection4 {
	id: number;
	name: string;
	coaching_evidence: string;
	reported_to: string;
	ticket_number: string;
	severity_rating: string;
	notifiable_incident: boolean;
	code_of_conduct_breach: boolean;
}

export interface IVehicleCreateIncidentSection4Payload {
	id: number;
	incident_category: {
		label: string;
		name: string;
		is_selected: boolean;
		description: string;
	}[];
	life_saving_rules: {
		label: string;
		name: string;
		is_selected: boolean;
		description: string;
	}[];
	code_of_conduct: {
		label: string;
		name: string;
		is_selected: boolean;
		description: string;
	}[];
}

export interface IVehicleCreateIncidentSection5 {
	id: number;
	name: string;
	absent_failed_defense: string;
	action_description: string;
	action_taken: string;
	individual_factors: string;
	organisational_factors: string;
	organisational_factors_two: string;
	percentage_of_shift_worked: string;
	portion_of_shift_worked: string;
	shift_start_time: string;
	add_action_required: boolean;
	completed: boolean;
	incident_investigation_required: boolean;
}

export interface IVehicleCreateIncidentSection5Payload {
	id: number;
	hierachy_of_control: {
		label: string;
		name: string;
		is_selected: boolean;
		description: string;
	}[];
}
export interface IVehicleIncidentSection2 {
	id: number;
	createdAt?: string;
	person_name: string | null;
	incident_time: string | null;
	position: string | null;
	employment_status: string | null;
	witness_exists: boolean;
	witnesses?: {
		id: number;
		witness_name: string;
		witness_time: string;
		witness_position: string;
		witness_employment_status: string;
	}[];
}

export interface IVehicleIncidentSection3 {
	id: number;
	injury_type_other_description: string;
	incident_mechanism_other_description: string;
	incident_agency_other_description: string;
	body_injured_other_description: string;
	nuisance_report: boolean;
	injuries: boolean;
	body_injured: {
		id: number;
		title: string;
		checked: boolean;
	}[];
	injury_type: {
		id: number;
		title: string;
		checked: boolean;
	}[];
	incident_mechanism: {
		id: number;
		title: string;
		checked: boolean;
	}[];
	incident_agency: {
		id: number;
		title: string;
		checked: boolean;
	}[];
}

export interface IVehicleIncidentSection4 {
	id: number;
	severity_rating: string;
	ticket_number: string;
	reported_to: string;
	coaching_evidence?: string;
	coaching_evidence_file?: {
		id: number;
		name: string;
	} | null;
	notifiable_incident: boolean;
	life_saving_rule_breach: boolean;
	code_of_conduct_breach: boolean;
	incident_category: {
		id: number;
		title: string;
		checked: boolean;
		description: string;
	}[];
	life_saving_rules: {
		id: number;
		title: string;
		checked: boolean;
		description: string;
	}[];
	code_of_conduct: {
		id: number;
		title: string;
		checked: boolean;
	}[];
}

export interface IVehicleIncidentSection5 {
	id: number;
	name?: string;
	createdAt?: string;
	updatedAt?: string;
	shift_start_time: string;
	incident_investigation_required: boolean;
	add_action_required: boolean;
	completed: boolean;
	organisational_factors?: string;
	organisational_factors_two?: string;
	individual_factors?: string;
	absent_failed_defense?: string;
	action_description: string;
	completion_date: string;
	action_taken: string;
	portion_of_shift_worked: string;
	percentage_of_shift_worked: string;
	hierachy_of_control: {
		id: number;
		title: string;
		checked: boolean;
	}[];
	assignee: string;
	investigator: string;
	users: {
		id: string;
		label: string;
	}[];
	section1Id: number | null;
	incidentReportId: number | null;
}

export type ISection1Type =
	| 'description'
	| 'location'
	| 'client'
	| 'incident_time'
	| 'jobDetails'
	| 'equipmentNumber'
	| 'ticketNumber'
	| 'personReported'
	| 'incident_date'
	| 'reported_date'
	| 'project'
	| 'branch';

export type ISection2Type =
	| 'person_name'
	| 'incident_time'
	| 'position'
	| 'employment_status'
	| 'witness_employment_status'
	| 'witness_name'
	| 'witness_position'
	| 'witness_time';

export interface IFilters {
	[name: string]: IOption[] | string | null;
}
export interface IOption {
	id: number;
	title: string;
	checked: boolean;
}

const type = [
	{ id: 1, title: 'Incident was notifiable', checked: false },
	{ id: 2, title: 'Was a breach of Life Saving Rules', checked: false },
	{ id: 3, title: 'Was a breach of Code of Conduct', checked: false },
];

const client = [{ id: 1, title: '', checked: false }];

const branch = [{ id: 1, title: '', checked: false }];

const severity = [
	{ id: 1, title: 'Normal', checked: false },
	{ id: 2, title: 'Medium', checked: false },
	{ id: 3, title: 'High', checked: false },
];

const status = [
	{ id: 1, title: 'Open', checked: false },
	{ id: 2, title: 'Close', checked: false },
	{ id: 3, title: 'Draft', checked: false },
];

export const initialFilterState: IFilters = {
	type,
	client,
	branch,
	severity,
	status,
	to: null,
	from: null,
};

export interface IOption {
	id: number;
	title: string;
	checked: boolean;
	description?: string;
}

export const section4RadioButtons = [
	{
		id: '1',
		title: 'Level 1',
	},
	{
		id: '2',
		title: 'Level 2',
	},
	{
		id: '3',
		title: 'Level 3',
	},
	{
		id: '4',
		title: 'Level 4',
	},
	{
		id: '5',
		title: 'Level 5',
	},
];

export const section5PercentageRadioButtons = [
	{
		id: '1',
		title: 'N/A',
	},
	{
		id: '2',
		title: '0% - 10%',
	},
	{
		id: '3',
		title: '11% - 50%',
	},
	{
		id: '4',
		title: '51% - 80%',
	},
	{
		id: '5',
		title: '81% - 100%',
	},
	{
		id: '6',
		title: '100% +',
	},
];

export const section5PortionRadioButtons = [
	{
		id: '1',
		title: 'Immediate',
	},
	{
		id: '2',
		title: 'Medium',
	},
	{
		id: '3',
		title: 'Long',
	},
];

//Keeping all this data for now to transfer to JSON when we need to create reports in future ticket
export const bodyParts = [
	{ id: 1, title: 'Left', checked: false },
	{ id: 2, title: 'First Right', checked: false },
	{ id: 3, title: 'Upper', checked: false },
	{ id: 4, title: 'Lower', checked: false },
];

export const bodyPartsInjured = [
	{ id: 5, title: 'Finger', checked: false },
	{ id: 6, title: 'Arm', checked: false },
	{ id: 7, title: 'Neck', checked: false },
	{ id: 8, title: 'Nose', checked: false },
	{ id: 9, title: 'Jaw', checked: false },
	{ id: 10, title: 'Hip', checked: false },
	{ id: 11, title: 'Foot', checked: false },
	{ id: 12, title: 'Hand', checked: false },
	{ id: 13, title: 'Elbow', checked: false },
	{ id: 14, title: 'Forearm', checked: false },
	{ id: 15, title: 'Head', checked: false },
	{ id: 16, title: 'Ear', checked: false },
	{ id: 17, title: 'Collar', checked: false },
	{ id: 18, title: 'Rib', checked: false },
	{ id: 19, title: 'Mouth/Teeth', checked: false },
	{ id: 20, title: 'Leg', checked: false },
	{ id: 21, title: 'Knee', checked: false },
	{ id: 22, title: 'Shin', checked: false },
	{ id: 23, title: 'Calf', checked: false },
	{ id: 24, title: 'Toe', checked: false },
	{ id: 25, title: 'Wirst', checked: false },
	{ id: 26, title: 'Shoulder', checked: false },
	{ id: 27, title: 'Eye', checked: false },
	{ id: 28, title: 'Back', checked: false },
	{ id: 29, title: 'Psych', checked: false },
	{ id: 30, title: 'Ankle', checked: false },
	{
		id: 31,
		title: 'Internal Organs',
		checked: false,
	},
	{ id: 32, title: 'Other', checked: false },
];

export const injuryType = [
	{
		id: 1,
		title: 'Sprain/Strain',
		checked: false,
	},
	{
		id: 2,
		title: 'Foreign Body',
		checked: false,
	},
	{ id: 3, title: 'Dislocation', checked: false },
	{
		id: 4,
		title: 'Heat Illness',
		checked: false,
	},
	{
		id: 5,
		title: 'Electric Shock',
		checked: false,
	},
	{ id: 6, title: 'Crush', checked: false },
	{
		id: 7,
		title: 'Bruise/Contusion',
		checked: false,
	},
	{
		id: 8,
		title: 'Fracture/Break',
		checked: false,
	},
	{
		id: 9,
		title: 'Consciousness',
		checked: false,
	},
	{
		id: 10,
		title: 'Exposure Substance',
		checked: false,
	},
	{
		id: 11,
		title: 'Cut/Laceration',
		checked: false,
	},
	{ id: 12, title: 'Burn', checked: false },
	{
		id: 13,
		title: 'Inflammation',
		checked: false,
	},
	{ id: 14, title: 'Respiratory', checked: false },
	{ id: 15, title: 'Dermatitis', checked: false },
	{
		id: 16,
		title: 'Allergy/Reaction',
		checked: false,
	},
	{ id: 17, title: 'Stress', checked: false },
	{ id: 18, title: 'Death', checked: false },
	{ id: 19, title: 'Other', checked: false },
];

export const incidentMechanism = [
	{
		id: 1,
		title: 'Exposure to Heat',
		checked: false,
	},
	{
		id: 2,
		title: 'Exposure to Cold',
		checked: false,
	},
	{
		id: 3,
		title: 'Fall - Same Level',
		checked: false,
	},
	{
		id: 4,
		title: 'Heat Fall - Different Level incl. Height',
		checked: false,
	},
	{ id: 5, title: 'Struck By', checked: false },
	{
		id: 6,
		title: 'Struck Against',
		checked: false,
	},
	{
		id: 7,
		title: 'Contact With',
		checked: false,
	},
	{
		id: 8,
		title: 'Manual Handling - Lifting',
		checked: false,
	},
	{
		id: 9,
		title: 'Caught Between',
		checked: false,
	},
	{ id: 10, title: 'Slip / Trip', checked: false },
	{
		id: 11,
		title: 'Absorption/Ingestion or Inhalation',
		checked: false,
	},
	{
		id: 12,
		title: 'Using Tools/Equipment',
		checked: false,
	},
	{
		id: 13,
		title: 'Repetitive Motion',
		checked: false,
	},
	{
		id: 14,
		title: 'Falling/Moving Object',
		checked: false,
	},
	{ id: 15, title: 'Bitten', checked: false },
	{
		id: 16,
		title: 'Fitness for Work',
		checked: false,
	},
	{ id: 17, title: 'Other', checked: false },
];

export const incidentAgency = [
	{
		id: 1,
		title: 'Stop/Slow Bat',
		checked: false,
	},
	{
		id: 2,
		title: 'Motor Vehicle - Ute',
		checked: false,
	},
	{
		id: 3,
		title: 'Motor Vehicle - Car',
		checked: false,
	},
	{
		id: 4,
		title: 'Motor Vehicle - Truck',
		checked: false,
	},
	{
		id: 5,
		title: 'Trailer other Vehicle Attachment',
		checked: false,
	},
	{
		id: 6,
		title: 'Boxes/Crates/Cartons etc',
		checked: false,
	},
	{ id: 7, title: 'Needles', checked: false },
	{
		id: 8,
		title: 'Loading/Unloading',
		checked: false,
	},
	{
		id: 9,
		title: 'Forklift Trucks',
		checked: false,
	},
	{
		id: 10,
		title: 'Containers/Cylinders etc',
		checked: false,
	},
	{ id: 11, title: 'Ladders', checked: false },
	{ id: 12, title: 'Electricity', checked: false },
	{
		id: 13,
		title: 'Lifting Devices',
		checked: false,
	},
	{
		id: 14,
		title: 'Tools & Equipment',
		checked: false,
	},
	{
		id: 15,
		title: 'Temperature Extremes',
		checked: false,
	},
	{
		id: 16,
		title: 'Mobile Plant',
		checked: false,
	},
	{
		id: 17,
		title: 'Bags/Sacks/Drums etc',
		checked: false,
	},
	{
		id: 18,
		title: 'Signs & Signage Equipment',
		checked: false,
	},
	{
		id: 19,
		title: 'Bunting/Taper & Witches Hats',
		checked: false,
	},
	{ id: 20, title: 'Dust/Debris', checked: false },
	{ id: 21, title: 'Noise', checked: false },
	{
		id: 22,
		title: 'Pressurized Liquid',
		checked: false,
	},
	{
		id: 23,
		title: 'Wet/Icy Surfaces',
		checked: false,
	},
	{
		id: 24,
		title: 'Uneven/Surfaces',
		checked: false,
	},
	{
		id: 25,
		title: 'Unprotect edges',
		checked: false,
	},
	{
		id: 26,
		title: 'Electrical Current',
		checked: false,
	},
	{ id: 27, strapi_label: 'radiation', title: 'Radiation/UV', checked: false },
	{
		id: 28,
		title: 'Chemicals & Substances',
		checked: false,
	},
	{
		id: 29,
		title: 'Doors/Windows',
		checked: false,
	},
	{
		id: 30,
		title: 'Walkways & Floors',
		checked: false,
	},
	{ id: 31, strapi_label: 'ergonomic', title: 'Ergonomic', checked: false },
	{
		id: 32,
		title: 'Animal or Insect',
		checked: false,
	},
	{ id: 33, title: 'Other Human', checked: false },
	{ id: 34, title: 'Asbestos/Lead', checked: false },
	{ id: 35, title: 'Steps or Stairs', checked: false },
	{
		id: 36,
		title: 'Body Motion or Posture',
		checked: false,
	},
	{ id: 37, title: 'Drugs', checked: false },
	{ id: 38, title: 'Alcohol', checked: false },
	{ id: 39, title: 'Other', checked: false },
];
