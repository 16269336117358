const incidentCategoryData = [
	{
		label: 'Lost Time Injury (LTI)',
		name: 'lost_time_injury',
		is_selected: false,
		description:
			'Any workplace injury or illness resulting in the loss of once complete shift or more.',
	},
	{
		label: 'Medical Treatment Injury (MTI)',
		name: 'medical_treatment_injury',
		is_selected: false,
		description:
			'Any workplace injury or illness resulting in treatment by a Medical Practitioner',
	},
	{
		label: 'First Aid Injury (FAI)',
		name: 'first_aid_injury',
		is_selected: false,
		description:
			'Any workplace injury or illness resulting in first aid treatment being administered',
	},
	{
		label: 'No Treatment Injury (NOT)',
		name: 'no_treatment_injury',
		is_selected: false,
		description:
			'Any workplace injury or illness resulting in an injury or illness being reported, however no treatment of any description was required',
	},
	{
		label: 'Non-Compliance (NC)',
		name: 'non_compliance',
		is_selected: false,
		description:
			'Any workplace incident resulting from a failure to comply with policy, procedure or process',
	},
	{
		label: 'Plant & Equipment Incident (PED)',
		name: 'plant_and_equipment',
		is_selected: false,
		description:
			'Any workplace incident resulting in damage to plant or equipment, excluding Motor Vehicles. Examples: Traffic Mounted Attenuators, Signage',
	},
	{
		label: 'Motor Vehicle Incident (MVI)',
		name: 'motor_vehicle_incident_mvi',
		is_selected: false,
		description:
			'Any workplace incident resulting in damage to a motor vehicle, including 3rd party vehicles, which is deemed as Altus being at fault. Examples: utes, trucks',
	},
	{
		label: 'Motor Vehicle Incident (MVI3)',
		name: 'motor_vehicle_incident_mvi3',
		is_selected: false,
		description:
			'Any workplace incident resulting in damage to a motor vehicle, including 3rd party vehicles, which is deemed as the 3rd party being at fault. Examples: utes, trucks',
	},
	{
		label: 'Motor Vehicle Incident (MVI4)',
		name: 'motor_vehicle_incident_mvi4',
		is_selected: false,
		description:
			'Any motor vehicle incident occuring within the boundaries of an Altus site involving 3rd party vehicles only (no Altus vehicles)',
	},
	{
		label: 'Property Incident (PI)',
		name: 'property_incident',
		is_selected: false,
		description:
			'Any workplace incident resulting in damage to property, excluding plant & equipment and motor vehicles. Examples: buildings, windows, awnings',
	},
	{
		label: 'Environment Incident (ENV)',
		name: 'environment_incident',
		is_selected: false,
		description:
			'Any workplace incident resulting in damage to the environment. Examples: Spill of hazardous substance or dangerous goods.',
	},
	{
		label: 'Near Miss Incident (NMI)',
		name: 'near_miss_incident',
		is_selected: false,
		description:
			'Any workplace incident, which does not result in injury or illness but had the potential to do so',
	},
	{
		label: 'Client Complaint (CC)',
		name: 'client_complaint',
		is_selected: false,
		description: 'Any complaint from an Altus client',
	},
	{
		label: 'Public Complaint (PC)',
		name: 'public_complaint',
		is_selected: false,
		description: 'Any complaint received from a member of the public',
	},
	{
		label: 'Other Complaint (OC)',
		name: 'other_complaint',
		is_selected: false,
		description:
			'Complaints from sources other than clients or the general public such as from other Altus staff, third party stakeholders including Local Councils, Main Roads Authorities, Police etc',
	},
	{
		label: 'Other (CO)',
		name: 'other',
		is_selected: false,
		description:
			'Any workplace incident resulting in a complaint or other incident not otherwise categorised',
	},
];

const lifeSavingRulesData = [
	{
		label: 'Drug and Alcohol Free Workplace',
		name: 'drug_and_alcohol_free_workplace',
		is_selected: false,
		description: '- Attend work free of any trace alcohol or illegal drugs',
	},
	{
		label: 'Safe Driving',
		name: 'safe_driving',
		is_selected: false,
		description:
			'- Drive to conditions and follow safe driving laws, procedures and rules',
	},
	{
		label: 'Exclusion Zones',
		name: 'exclusion_zones',
		is_selected: false,
		description:
			'- Maintain established exclusion and drop zones around mobile plant and machinery',
	},
	{
		label: 'Mobile Phones',
		name: 'mobile_phones',
		is_selected: false,
		description: '- No use of phones while performing Stop/Slow duties',
	},
	{
		label: 'Working In and Around Live Traffic',
		name: 'working_around_live_traffic',
		is_selected: false,
		description: '- Minimise exposure to live traffic',
	},
	{
		label: 'Safety Controls',
		name: 'safety_controls',
		is_selected: false,
		description: '- Follow all procedures for safety critical activities',
	},
];

const codeOfConductData = [
	{
		label: 'Personal Conduct',
		name: 'personal_conduct',
		is_selected: false,
		description: '',
	},
	{
		label: 'Respect for Others',
		name: 'respect_for_others',
		is_selected: false,
		description: '',
	},
	{
		label: 'Community and Environment',
		name: 'community_and_environment',
		is_selected: false,
		description: '',
	},
	{
		label: 'Standard of Dress & Appearance',
		name: 'standard_of_dress_and_appearance',
		is_selected: false,
		description: '',
	},
	{
		label: 'Attendance and Availability',
		name: 'attendance_and_availability',
		is_selected: false,
		description: '',
	},
	{
		label: 'Conflict of Interest',
		name: 'conflict_of_interest',
		is_selected: false,
		description: '',
	},
];

export { incidentCategoryData, lifeSavingRulesData, codeOfConductData };
