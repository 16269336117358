import Select from 'components/atoms/Select';
import React, { useEffect, useState } from 'react';
import { TrashIcon } from '@heroicons/react/outline';

export interface IFault {
	id: number;
	fault_type: string;
	priority: string;
}

export interface IVehicleFault {
	className?: string;
	handleVehicleFaultUpdate: (
		property: string,
		sectionLabel: string,
		index?: number
	) => void;
	id: number;
	deleteFault(id: number): void;
	faultOptions: { id: string; label: string }[];
	priorityOptions: { id: string; label: string }[];
	index: number;
	fault: IFault;
}

const VehicleFault: React.FC<IVehicleFault> = ({
	handleVehicleFaultUpdate,
	id,
	deleteFault,
	fault,
	index,
	faultOptions,
	priorityOptions,
}) => {
	const [payload, setPayload] = useState(fault);

	useEffect(() => {
		setPayload(fault);
	}, [fault]);

	const handleOnChange = (property: string, value: string) => {
		handleVehicleFaultUpdate(property, value, index);
	};

	return (
		<div className="flex flex-row gap-6 items-end pb-4">
			<div>
				<Select
					label="Fault"
					options={faultOptions}
					onChange={(value: string) => handleOnChange('fault_type', value)}
					className="w-[425px]"
					required={true}
					selected={payload.fault_type}
				/>
			</div>
			<div>
				<Select
					label="Priority"
					options={priorityOptions}
					onChange={(value: string) => handleOnChange('priority', value)}
					className="w-[425px]"
					required={true}
					selected={payload.priority}
				/>
			</div>
			<div className="flex items-center">
				<TrashIcon
					height={20}
					width={18}
					className="cursor-pointer text-secondary-50 align-bottom"
					onClick={() => {
						deleteFault(id);
					}}
				/>
			</div>
		</div>
	);
};

export default VehicleFault;
