import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { PaperClipIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';

export interface IFileInputDragDrop {
	label?: string;
	id?: string;
	onChange: (file: File[]) => void;
	defaultFileName?: string;
	clearIcon?: boolean;
	maxFiles?: number;
}

const FileInputDragDrop: React.FC<IFileInputDragDrop> = ({
	label = '',
	id = '',
	onChange,
	defaultFileName = '',
	clearIcon = false,
	maxFiles = 1,
}) => {
	const [fileName, setFileName] = useState(defaultFileName);
	const [xIcon, setXIcon] = useState(false);
	useEffect(() => {
		setFileName(defaultFileName);
		setXIcon(clearIcon);
	}, [defaultFileName, clearIcon]);

	const { getRootProps, getInputProps } = useDropzone({
		maxFiles: maxFiles,
		onDrop: (acceptedFiles: File[]) => {
			onChange(
				acceptedFiles.map((file: File) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);

			setFileName(acceptedFiles[0].name);
			setXIcon(true);
		},
	});

	const handleOnChange = (value: string) => {
		setFileName(value);
		setXIcon(!xIcon);
	};

	return (
		<section className="ml-4 w-1/3">
			{label && (
				<div>
					<label
						htmlFor={id}
						className="block font-inter text-base font-semibold tracking-normal text-secondary mb-1"
					>
						{label}
					</label>
				</div>
			)}
			<div className="w-full cursor-pointer flex flex-row justify-between py-3 bg-white border border-gray-300 rounded-sm h-12">
				<div
					{...getRootProps({
						className: 'dropzone w-[90%]',
					})}
				>
					<input {...getInputProps()} />
					<p className="text-secondary-200 pl-2 overflow-hidden">
						{fileName !== '' ? fileName : 'Drag & Drop Attached File'}
					</p>
				</div>
				{xIcon ? (
					<div className={`mr-4`}>
						<XIcon
							className="h-5 w-5 text-gray-500"
							onClick={() => handleOnChange('')}
						/>
					</div>
				) : (
					<PaperClipIcon height={20} width={18} className="mr-4" />
				)}
			</div>
		</section>
	);
};

export default FileInputDragDrop;
