import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import Heading from 'components/atoms/Heading';

export interface ISearch {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	searchType?: 'fault' | 'incident' | 'client' | 'team leads';
	searchHeading?: string;
	children?: React.ReactNode;
}

const Search: React.FC<ISearch> = ({
	open,
	setOpen,
	searchType,
	searchHeading,
	children,
}) => {
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-30" onClose={setOpen}>
				<div className="fixed inset-0" />
				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pl-[113px] pt-[106px]">
							<Transition.Child
								as={Fragment}
								enter="ease-in-out duration-500"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in-out duration-500"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
							</Transition.Child>
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto w-screen max-w-md">
									<div className="flex h-full w-[302px] flex-col overflow-y-scroll bg-primary-50 py-6 shadow-xl">
										<div className="mx-auto w-[222px]">
											{searchType === 'fault' || searchType === 'incident' ? (
												<div className="flex items-start justify-between">
													<Heading type="h2">Search By</Heading>
													<div className="ml-3 flex h-7 items-center my-auto">
														<button
															type="button"
															className="rounded-md text-orange-500 hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mx-auto"
															onClick={() => setOpen(false)}
														>
															<span className="sr-only">Close panel</span>
															<XIcon className="h-6 w-6" aria-hidden="true" />
														</button>
													</div>
												</div>
											) : (
												<div>
													<Heading type="h2" colour="primary">
														{searchHeading}
													</Heading>
													<Heading type="h2">Feedback Report</Heading>
												</div>
											)}
										</div>
										<div className="relative mt-6 flex-1 px-4 sm:px-6 space-y-4 justify-center">
											{children}
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default Search;
