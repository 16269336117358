//This file stores checkbox group data which is used to create the incident section and prepopulate strapi

const bodyInjuredData = [
	{
		label: 'Left',
		name: 'left',
		is_selected: false,
		description: '',
	},
	{
		label: 'First Right',
		name: 'first_right',
		is_selected: false,
		description: '',
	},
	{
		label: 'Upper',
		name: 'upper',
		is_selected: false,
		description: '',
	},
	{
		label: 'Lower',
		name: 'lower',
		is_selected: false,
		description: '',
	},
	{
		label: 'Finger',
		name: 'finger',
		is_selected: false,
		description: '',
	},
	{
		label: 'Arm',
		name: 'arm',
		is_selected: false,
		description: '',
	},
	{
		label: 'Neck',
		name: 'neck',
		is_selected: false,
		description: '',
	},
	{
		label: 'Nose',
		name: 'nose',
		is_selected: false,
		description: '',
	},
	{
		label: 'Jaw',
		name: 'jaw',
		is_selected: false,
		description: '',
	},
	{
		label: 'Hip',
		name: 'hip',
		is_selected: false,
		description: '',
	},
	{
		label: 'Foot',
		name: 'foot',
		is_selected: false,
		description: '',
	},
	{
		label: 'Hand',
		name: 'hand',
		is_selected: false,
		description: '',
	},
	{
		label: 'Elbow',
		name: 'elbow',
		is_selected: false,
		description: '',
	},
	{
		label: 'Forearm',
		name: 'forearm',
		is_selected: false,
		description: '',
	},
	{
		label: 'Head',
		name: 'head',
		is_selected: false,
		description: '',
	},
	{
		label: 'Ear',
		name: 'ear',
		is_selected: false,
		description: '',
	},
	{
		label: 'Collar',
		name: 'collar',
		is_selected: false,
		description: '',
	},
	{
		label: 'Rib',
		name: 'rib',
		is_selected: false,
		description: '',
	},
	{
		label: 'Mouth/Teeth',
		name: 'mouth_teeth',
		is_selected: false,
		description: '',
	},
	{
		label: 'Leg',
		name: 'leg',
		is_selected: false,
		description: '',
	},
	{
		label: 'Knee',
		name: 'knee',
		is_selected: false,
		description: '',
	},
	{
		label: 'Shin',
		name: 'shin',
		is_selected: false,
		description: '',
	},
	{
		label: 'Calf',
		name: 'calf',
		is_selected: false,
		description: '',
	},
	{
		label: 'Toe',
		name: 'toe',
		is_selected: false,
		description: '',
	},
	{
		label: 'Wrist',
		name: 'wrist',
		is_selected: false,
		description: '',
	},
	{
		label: 'Shoulder',
		name: 'shoulder',
		is_selected: false,
		description: '',
	},
	{
		label: 'Eye',
		name: 'eye',
		is_selected: false,
		description: '',
	},
	{
		label: 'Back',
		name: 'back',
		is_selected: false,
		description: '',
	},
	{
		label: 'Psych',
		name: 'psych',
		is_selected: false,
		description: '',
	},
	{
		label: 'Ankle',
		name: 'ankle',
		is_selected: false,
		description: '',
	},
	{
		label: 'Internal Organs',
		name: 'internal_organs',
		is_selected: false,
		description: '',
	},
	{
		label: 'Other',
		name: 'other',
		is_selected: false,
		description: '',
	},
];

const injuryTypeData = [
	{
		label: 'Sprain/Strain',
		name: 'sprain_strain',
		is_selected: false,
		description: '',
	},
	{
		label: 'Foreign Body',
		name: 'foreign_body',
		is_selected: false,
		description: '',
	},
	{
		label: 'Dislocation',
		name: 'dislocation',
		is_selected: false,
		description: '',
	},
	{
		label: 'Heat Illness',
		name: 'heat_illness',
		is_selected: false,
		description: '',
	},
	{
		label: 'Electric Shock',
		name: 'electric_shock',
		is_selected: false,
		description: '',
	},
	{
		label: 'Crush',
		name: 'crush',
		is_selected: false,
		description: '',
	},
	{
		label: 'Bruise/Contusion',
		name: 'bruise_contusion',
		is_selected: false,
		description: '',
	},
	{
		label: 'Fracture/Break',
		name: 'fracture_break',
		is_selected: false,
		description: '',
	},
	{
		label: 'Consciousness',
		name: 'consciousness',
		is_selected: false,
		description: '',
	},
	{
		label: 'Exposure Substance',
		name: 'exposure_substance',
		is_selected: false,
		description: '',
	},
	{
		label: 'Cut/Laceration',
		name: 'cut_laceration',
		is_selected: false,
		description: '',
	},
	{
		label: 'Burn',
		name: 'burn',
		is_selected: false,
		description: '',
	},
	{
		label: 'Inflammation',
		name: 'inflammation',
		is_selected: false,
		description: '',
	},
	{
		label: 'Respiratory',
		name: 'respiratory',
		is_selected: false,
		description: '',
	},
	{
		label: 'Dermatitis',
		name: 'dermatitis',
		is_selected: false,
		description: '',
	},
	{
		label: 'Allergy/Reaction',
		name: 'allergy_reaction',
		is_selected: false,
		description: '',
	},
	{
		label: 'Stress',
		name: 'stress',
		is_selected: false,
		description: '',
	},
	{
		label: 'Death',
		name: 'death',
		is_selected: false,
		description: '',
	},
	{
		label: 'Other',
		name: 'other',
		is_selected: false,
		description: '',
	},
];

const incidentMechanismData = [
	{
		label: 'Exposure to Heat',
		name: 'exposure_to_heat',
		is_selected: false,
		description: '',
	},
	{
		label: 'Exposure to Cold',
		name: 'exposure_to_cold',
		is_selected: false,
		description: '',
	},
	{
		label: 'Fall - Same Level',
		name: 'fall_same_level',
		is_selected: false,
		description: '',
	},
	{
		label: 'Heat Fall - Different Level incl. Height',
		name: 'heat_fall_different_level',
		is_selected: false,
		description: '',
	},
	{
		label: 'Struck By',
		name: 'struck_by',
		is_selected: false,
		description: '',
	},
	{
		label: 'Struck Against',
		name: 'struck_against',
		is_selected: false,
		description: '',
	},
	{
		label: 'Contact With',
		name: 'contact_with',
		is_selected: false,
		description: '',
	},
	{
		label: 'Exposure Substance',
		name: 'exposure_substance',
		is_selected: false,
		description: '',
	},
	{
		label: 'Caught Between',
		name: 'caught_between',
		is_selected: false,
		description: '',
	},
	{
		label: 'Slip / Trip',
		name: 'slip_trip',
		is_selected: false,
		description: '',
	},
	{
		label: 'Absorption/Ingestion or Inhalation',
		name: 'absorption_ingestion_inhalation',
		is_selected: false,
		description: '',
	},
	{
		label: 'Using Tools/Equipment',
		name: 'using_tools_equipment',
		is_selected: false,
		description: '',
	},
	{
		label: 'Allergy/Reaction',
		name: 'allergy_reaction',
		is_selected: false,
		description: '',
	},
	{
		label: 'Repetitive Motion',
		name: 'repetitive_motion',
		is_selected: false,
		description: '',
	},
	{
		label: 'Falling/Moving Object',
		name: 'falling_moving_object',
		is_selected: false,
		description: '',
	},
	{
		label: 'Bitten',
		name: 'bitten',
		is_selected: false,
		description: '',
	},
	{
		label: 'Fitness for Work',
		name: 'fitness_for_work',
		is_selected: false,
		description: '',
	},
	{
		label: 'Other',
		name: 'other',
		is_selected: false,
		description: '',
	},
];

const incientAgencyData = [
	{
		label: 'Stop/Slow Bat',
		name: 'stop_slow_bat',
		is_selected: false,
		description: '',
	},
	{
		label: 'Motor Vehicle - Ute',
		name: 'motor_vehicle_ute',
		is_selected: false,
		description: '',
	},
	{
		label: 'Motor Vehicle - Car',
		name: 'motor_vehicle_car',
		is_selected: false,
		description: '',
	},
	{
		label: 'Motor Vehicle - Truck',
		name: 'motor_vehicle_truck',
		is_selected: false,
		description: '',
	},
	{
		label: 'Trailer other Vehicle Attachment',
		name: 'trailer_other_vehicle_attachment',
		is_selected: false,
		description: '',
	},
	{
		label: 'Boxes/Crates/Cartons etc',
		name: 'boxes_crates_cartons',
		is_selected: false,
		description: '',
	},
	{
		label: 'Needles',
		name: 'needles',
		is_selected: false,
		description: '',
	},
	{
		label: 'Loading/Unloading',
		name: 'loading_unloading',
		is_selected: false,
		description: '',
	},
	{
		label: 'Forklift Trucks',
		name: 'forklift_trucks',
		is_selected: false,
		description: '',
	},
	{
		label: 'Containers/Cylinders etc',
		name: 'containers_cylinders',
		is_selected: false,
		description: '',
	},
	{
		label: 'Ladders',
		name: 'ladders',
		is_selected: false,
		description: '',
	},
	{
		label: 'Electricity',
		name: 'electricity',
		is_selected: false,
		description: '',
	},
	{
		label: 'Lifting Devices',
		name: 'lifting_devices',
		is_selected: false,
		description: '',
	},
	{
		label: 'Tools & Equipment',
		name: 'tools_equipment',
		is_selected: false,
		description: '',
	},
	{
		label: 'Temperature Extremes',
		name: 'temperature_extremes',
		is_selected: false,
		description: '',
	},
	{
		label: 'Mobile Plant',
		name: 'mobile_plant',
		is_selected: false,
		description: '',
	},
	{
		label: 'Bags/Sacks/Drums etc',
		name: 'bags_sacks_drums',
		is_selected: false,
		description: '',
	},
	{
		label: 'Signs & Signage Equipment',
		name: 'sign_equipment',
		is_selected: false,
		description: '',
	},
	{
		label: 'Bunting/Taper & Witches Hats',
		name: 'bunting_taper',
		is_selected: false,
		description: '',
	},
	{
		label: 'Dust/Debris',
		name: 'dust_debris',
		is_selected: false,
		description: '',
	},
	{
		label: 'Noise',
		name: 'noise',
		is_selected: false,
		description: '',
	},
	{
		label: 'Pressurized Liquid',
		name: 'pressurized_liquid',
		is_selected: false,
		description: '',
	},
	{
		label: 'Wet/Icy Surfaces',
		name: 'wet_surfaces',
		is_selected: false,
		description: '',
	},
	{
		label: 'Uneven/Surfaces',
		name: 'uneven_surfaces',
		is_selected: false,
		description: '',
	},
	{
		label: 'Unprotect edges',
		name: 'unprotect_edges',
		is_selected: false,
		description: '',
	},
	{
		label: 'Electrical Current',
		name: 'electrical_current',
		is_selected: false,
		description: '',
	},
	{
		label: 'Radiation/UV',
		name: 'radiation_uv',
		is_selected: false,
		description: '',
	},
	{
		label: 'Chemicals & Substances',
		name: 'chemicals_substances',
		is_selected: false,
		description: '',
	},
	{
		label: 'Doors/Windows',
		name: 'doors_windows',
		is_selected: false,
		description: '',
	},
	{
		label: 'Walkways & Floors',
		name: 'walkways_floors',
		is_selected: false,
		description: '',
	},
	{
		label: 'Ergonomic',
		name: 'ergonomic',
		is_selected: false,
		description: '',
	},
	{
		label: 'Animal or Insect',
		name: 'animal_insect',
		is_selected: false,
		description: '',
	},
	{
		label: 'Other Human',
		name: 'other_human',
		is_selected: false,
		description: '',
	},
	{
		label: 'Asbestos/Lead',
		name: 'asbestos',
		is_selected: false,
		description: '',
	},
	{
		label: 'Steps or Stairs',
		name: 'stairs',
		is_selected: false,
		description: '',
	},
	{
		label: 'Body Motion or Posture',
		name: 'body_motion_posture',
		is_selected: false,
		description: '',
	},
	{
		label: 'Drugs',
		name: 'drugs',
		is_selected: false,
		description: '',
	},
	{
		label: 'Alcohol',
		name: 'alcohol',
		is_selected: false,
		description: '',
	},
	{
		label: 'Other',
		name: 'other',
		is_selected: false,
		description: '',
	},
];

export {
	bodyInjuredData,
	injuryTypeData,
	incidentMechanismData,
	incientAgencyData,
};
