import Heading from 'components/atoms/Heading';
import { useEffect, useState } from 'react';
import { useUnsubscribeUserMutation } from 'redux/api/unsubscribeAPI';
import { useQuery } from 'redux/hooks';

const Unsubscribe = () => {
	const query = useQuery();
	const [message, setMessage] = useState('');
	const [unsubscribeUser, { isLoading, isSuccess, isError }] =
		useUnsubscribeUserMutation();

	useEffect(() => {
		const email = query.get('email');
		if (!email) {
			setMessage('Error: email not provided');
		} else if (isLoading) {
			setMessage('Unsubscribing...');
		} else if (isSuccess || isError) {
			setMessage('User Unsubscribed successfully');
		} else {
			unsubscribeUser({ email });
		}
	}, [query, unsubscribeUser, isLoading, isSuccess, isError]);

	return (
		<main className="flex flex-col grow items-center justify-center h-screen">
			<div className="flex flex-col items-center bg-white w-full">
				<Heading type="h1">
					{message} {isLoading}
				</Heading>
			</div>
		</main>
	);
};

export default Unsubscribe;
