import React from 'react';

const Divider: React.FC = () => {
	return (
		<div className="flex items-center">
			<div className="w-full border-t border-orange-200" />
		</div>
	);
};

export default Divider;
