import React from 'react';

export interface IStatusButton {
	className?: string;
	status:
		| 'open'
		| 'closed'
		| 'draft'
		| 'low'
		| 'medium'
		| 'high'
		| 'severe'
		| 'extreme'
		| 'completed'
		| 'submitted'
		| 'not opened';
}

const StatusButton: React.FC<IStatusButton> = ({ status, className = '' }) => {
	const textStyle = {
		open: 'text-red',
		closed: 'text-green',
		draft: 'text-blue',
		low: 'text-secondary',
		medium: 'text-secondary',
		high: 'text-secondary',
		severe: 'text-secondary',
		extreme: 'text-secondary',
		completed: 'text-green',
		submitted: 'text-blue',
		'not opened': 'text-red',
	};

	const borderStyle = {
		open: 'border-red',
		closed: 'border-green',
		draft: 'border-blue',
		low: 'border-secondary',
		medium: 'border-secondary',
		high: 'border-secondary',
		severe: 'border-secondary',
		extreme: 'border-secondary',
		completed: 'border-green',
		submitted: 'border-blue',
		'not opened': 'border-red',
	};

	return (
		<div
			className={`flex justify-center items-center flex-grow-0 flex-shrink-0 w-[90px] relative gap-2 p-1 rounded-[5px] bg-white border ${borderStyle[status]} ${className}`}
		>
			<p
				className={`flex-grow-0 flex-shrink-0 capitalize text-sm text-left ${textStyle[status]}`}
			>
				{status}
			</p>
		</div>
	);
};

export default StatusButton;
