import React, { useEffect, useState } from 'react';
import DateInput from 'components/atoms/DateInput';
import Button from 'components/atoms/Button';
import Divider from 'components/atoms/Divider';
import Accordion from 'components/molecules/Accordion';
import CheckBoxGroup from 'components/molecules/CheckBoxGroup';
import { useAppSelector } from 'redux/hooks';
import {
	updateIncidentFilterState,
	resetIncidentFilterState,
} from 'redux/slices/vehicleIncidentsSlice';
import { useDispatch } from 'react-redux';
import { filtersToQueryStringConverter } from 'utils/filters';
import { useGetVehicleIncidentsMutation } from 'redux/api/vehicleIncidents';

export interface IIncidentSearchBar {
	setFilters: React.Dispatch<React.SetStateAction<string>>;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const IncidentSearchBar: React.FC<IIncidentSearchBar> = ({
	setFilters,
	setOpen,
}) => {
	const dispatch = useDispatch();

	const filters = useAppSelector((state) => state.vehicleIncident.filters);
	const [getIncidents, { data }] = useGetVehicleIncidentsMutation();
	const [filterData, setFilterData] = useState(data?.sortData);

	useEffect(() => {
		getIncidents('all');
	}, [getIncidents]);

	useEffect(() => {
		setFilterData(data?.sortData);
	}, [data?.sortData]);

	useEffect(() => {
		if (data?.sortData && filterData !== undefined) {
			const branchData = data?.sortData[0]?.branch;
			const clienthData = data?.sortData[1]?.client;
			const severityData = data?.sortData[2]?.severity;
			const statusData = data?.sortData[3]?.status;

			const filterState = JSON.parse(JSON.stringify(filters));
			filterState['branch'] = branchData;
			filterState['client'] = clienthData;
			filterState['severity'] = severityData;
			filterState['status'] = statusData;
			dispatch(updateIncidentFilterState(filterState));
		}
		//removes dependency for "filters"
		// eslint-disable-next-line
	}, [setFilterData, filterData, dispatch, data?.sortData]);

	const onClick = () => {
		const query = filtersToQueryStringConverter(filters);
		setFilters(query);
		setOpen(false);
		dispatch(resetIncidentFilterState());
	};

	return (
		<>
			<Divider />
			<Accordion title="Type">
				<CheckBoxGroup filterName="type" filterType="incident" />
			</Accordion>
			<Divider />
			<Accordion title="Client">
				<CheckBoxGroup filterName="client" filterType="incident" />
			</Accordion>
			<Divider />
			<Accordion title="Severity">
				<CheckBoxGroup filterName="severity" filterType="incident" />
			</Accordion>
			<Divider />
			<Accordion title="Date Range">
				<DateInput
					inputLabel="From"
					placeholder="From"
					filterDateType="incident"
					iconClassName="text-secondary"
				/>
				<DateInput
					inputLabel="To"
					placeholder="To"
					filterDateType="incident"
					iconClassName="text-secondary"
				/>
			</Accordion>
			<Divider />
			<Accordion title="Branch">
				<CheckBoxGroup filterName="branch" filterType="incident" />
			</Accordion>
			<Divider />
			<Accordion title="Status">
				<CheckBoxGroup filterName="status" filterType="incident" />
			</Accordion>
			<Divider />
			<Button
				className="h-12 w-[222px] mx-auto my-6 font-bold"
				onClick={onClick}
			>
				Apply
			</Button>
		</>
	);
};

export default IncidentSearchBar;
