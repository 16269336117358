import React from 'react';

export interface ITabLink {
	className?: string;
	children: React.ReactNode;
	onClick?: () => any;
	active?: boolean;
	disabled?: boolean;
}

const TabLink: React.FC<ITabLink> = ({
	children,
	active = false,
	onClick,
	className = '',
	disabled = false,
}) => {
	return (
		<>
			{disabled ? (
				<div className=" text-primary-700 font-bold font-inter tracking-normal">
					{children}
				</div>
			) : (
				<div
					onClick={onClick}
					className={`flex justify-center items-center px-2 pb-2 bg-transparent cursor-pointer font-inter text-base font-bold tracking-normal
					${
						active
							? 'text-secondary border-b-2 border-primary'
							: 'text-secondary-50 hover:text-secondary-200'
					}
					${className}`}
				>
					{children}
				</div>
			)}
		</>
	);
};

export default TabLink;
