import { ReactNode, useEffect, useState } from 'react';
import SideBar from 'components/molecules/SideBar';
import Header from 'components/molecules/Header';
import { INavigation } from 'types/Layout';
import { logoutUser } from 'redux/slices/userSlice';
import { useAppDispatch } from 'redux/hooks';
import { useNavigate } from 'react-router-dom';

const defaultLinks: INavigation[] = [
	{
		name: 'Incidents',
		href: 'incidents',
		current: false,
		total: 23,
	},
	{
		name: 'Vehicle Faults',
		href: 'vehicle-faults',
		current: false,
		total: 23,
	},
	{
		name: 'Surveys',
		href: 'surveys',
		current: false,
		total: 23,
	},
	{ name: 'Users', href: 'users', current: false },
	{ name: 'Links', href: 'links', current: false },
];

export interface ILayout {
	url: 'incidents' | 'vehicle-faults' | 'surveys' | 'users' | 'links';
	children?: ReactNode;
	surveyDropdown?: boolean;
}

const MainLayout: React.FC<ILayout> = ({
	url,
	children,
	surveyDropdown = false,
}) => {
	const [links, setLinks] = useState(defaultLinks);

	const [selectedLink, setSelectedLink] = useState(
		defaultLinks.filter((link) => link.href === url)[0]
	);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(
		() => {
			if (links) {
				const newLinks = links.map((link) => {
					link.current = link.href === selectedLink.href;
					return link;
				});

				setLinks(newLinks);
				navigate(`/${selectedLink.href}`);
			}
		},
		// eslint-disable-next-line
		[selectedLink, setLinks]
	);

	const onLogout = () => {
		dispatch(logoutUser());
		navigate('/login');
	};

	const onEdit = () => {};

	return (
		<div className="flex-1 flex flex-row h-screen w-screen">
			<SideBar navigation={links} setSelected={setSelectedLink} />
			<div className="flex flex-1 flex-col">
				<Header
					data={selectedLink}
					onLogout={onLogout}
					onEdit={onEdit}
					surveyDropdown={surveyDropdown}
				/>
				<div className="relative overflow-y-scroll">{children}</div>
			</div>
		</div>
	);
};

export default MainLayout;
