import Input from 'components/atoms/Input';
import Select from 'components/atoms/Select';
import React, { useEffect, useState } from 'react';
import { ClockIcon, TrashIcon } from '@heroicons/react/outline';
import { ISection2Type } from 'types/VehicleIncidents';

export interface IWitnessCard {
	handleSection2Update: (
		property: ISection2Type,
		sectionLabel: string,
		index?: number
	) => void;
	employmentStatusOptions: { id: string; label: string }[];
	employmentStatusId: string;
	deleteWitness(id: number): void;
	id: number;
	witness: IWitness;
	index: number;
}

export interface IWitness {
	id: number;
	witness_name: string;
	witness_time: string;
	witness_position: string;
	witness_employment_status: string;
}

const WitnessCard: React.FC<IWitnessCard> = ({
	handleSection2Update,
	employmentStatusOptions,
	deleteWitness,
	witness,
	id,
	index,
}) => {
	const [payload, setPayload] = useState(witness);

	useEffect(() => {
		setPayload(witness);
	}, [witness]);

	const handleOnChange = (property: ISection2Type, value: string) => {
		handleSection2Update(property, value, index);
	};

	return (
		<div className="flex gap-x-8 w-full flex-wrap my-3">
			<Input
				type="text"
				onChange={(value: string) => handleOnChange('witness_name', value)}
				value={payload.witness_name}
				className="basis-1/5 grow"
				label="Name of Person"
				required={true}
			/>
			<Input
				type="time"
				onChange={(value: string) => handleOnChange('witness_time', value)}
				value={payload.witness_time}
				className="basis-1/5 grow mb-10"
				required={true}
				label="Incident Time"
				Icon={ClockIcon}
				iconClassName="text-black"
				iconPosition="trailing"
				placeholder="Select time"
			/>
			<Input
				type="text"
				onChange={(value: string) => handleOnChange('witness_position', value)}
				value={payload.witness_position}
				className="basis-1/5 grow"
				label="Position"
				required={true}
			/>
			<div className="basis-1/5 grow">
				<Select
					label="Employment Status"
					onChange={(value: string) =>
						handleOnChange('witness_employment_status', value)
					}
					options={employmentStatusOptions}
					placeholder="Select"
					className="mb-10"
					required={true}
					selected={payload.witness_employment_status}
				/>
			</div>
			<div className="flex items-center">
				<TrashIcon
					height={20}
					width={18}
					className="cursor-pointer"
					onClick={() => {
						deleteWitness(id);
					}}
				/>
			</div>
		</div>
	);
};

export default WitnessCard;
