import MainLayout from 'components/templates/MainLayout';
import { useEffect, useState } from 'react';
import Table from 'components/organisms/Table';
import Button from 'components/atoms/Button';
import { SearchIcon } from '@heroicons/react/solid';
import TabLink from 'components/atoms/TabLink';
import { IQueryPeriod } from 'types/VehicleFaults';
import SlideOver from 'components/molecules/SlideOver';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import CommentPanel from 'components/organisms/CommentPanel';
import { closeSurveyModal, closeCommentModal } from 'redux/slices/surveys';
import SurveyResultsPanel from 'components/organisms/SurveyResultsPanel';
import {
	useGetVehicleSurveySubmissionsMutation,
	useCreateSurveySubmissionActivityMutation,
	useGetSurveySubmissionActivityMutation,
	useUpdateSurveySubmissionActivityMutation,
	useDeleteSurveySubmissionActivityMutation,
} from 'redux/api/surveys';
import { useParams } from 'react-router-dom';
import Search from 'components/molecules/Search';
import LeadFeedbackSearchBar from 'components/molecules/LeadFeedbackSearchBar';
import ClientFeedbackSearchBar from 'components/molecules/ClientFeedbackSearchBar';
import TrafficControllerFeedbackSearchBar from 'components/molecules/TrafficControllerFeedbackSearchBar';

const Surveys = () => {
	const [period, setPeriod] = useState<IQueryPeriod>('today');
	const surveyState = useAppSelector((state) => state.surveys);
	const [queryString, setQueryString] = useState<string>(`period=today`);
	const [surveyType, setSurveyType] = useState(surveyState.surveyType);
	const [getSurveySubmissions, { data }] =
		useGetVehicleSurveySubmissionsMutation();
	const dispatch = useAppDispatch();
	const userPermission = useAppSelector((state) => state.user.permissions);
	const [createSurveySubmissionActivity] =
		useCreateSurveySubmissionActivityMutation();
	const [deleteSurveySubmissionActivity] =
		useDeleteSurveySubmissionActivityMutation();
	const [updateSurveySubmissionActivity] =
		useUpdateSurveySubmissionActivityMutation();
	const [getSurveySubmissionActivity, { data: activityData }] =
		useGetSurveySubmissionActivityMutation();
	const { uid } = useParams();
	const [clientOpen, setClientOpen] = useState(false);
	const [teamLeadsOpen, setTeamLeadsOpen] = useState(false);
	const [trafficControllerOpen, setTrafficControllerOpen] = useState(false);

	useEffect(() => {
		if (uid) {
			setQueryString(`uid=${uid}`);
		}
	}, [uid]);

	useEffect(() => {
		setSurveyType(surveyState.surveyType);
	}, [surveyState.surveyType]);

	useEffect(() => {
		getSurveySubmissions(`${queryString}&type=${surveyType}`);
	}, [queryString, getSurveySubmissions, surveyType]);

	const handleCloseComments = () => {
		dispatch(closeCommentModal());
	};

	const hasManagerAccess = () => {
		const permission = userPermission?.filter(
			(permission) =>
				permission.role === 'manager' &&
				permission.branch?.id === surveyState.surveyBranchId &&
				permission.survey_level === 'write'
		);

		if (!permission) {
			return false;
		}
		return permission.length > 0;
	};

	const headings = [
		'Recipient',
		'Survey Name',
		'Date Sent',
		'Branch',
		'Status',
	];

	const handleCloseSurveyPanel = () => {
		dispatch(closeSurveyModal());
	};

	const handleOpenFeedbackReport = () => {
		if (surveyType === 'Clients') {
			setClientOpen(true);
		}

		if (surveyType === 'Team Leads') {
			setTeamLeadsOpen(true);
		}

		if (surveyType === 'Traffic Controllers') {
			setTrafficControllerOpen(true);
		}
	};

	return (
		<MainLayout url="surveys" surveyDropdown={true}>
			<main className="">
				<div className="flex items-center justify-center py-7 px-4 sticky top-0 z-30 bg-white">
					<Button type="secondary">
						<SearchIcon className="h-5 w-5 mr-1" />
						Search
					</Button>
					<div className="flex items-center justify-center flex-1 w-full space-x-4">
						<TabLink
							onClick={() => {
								setQueryString('period=today');
								setPeriod('today');
							}}
							active={period === 'today'}
						>
							Today
						</TabLink>
						<TabLink
							onClick={() => {
								setQueryString('period=week');
								setPeriod('week');
							}}
							active={period === 'week'}
						>
							This Week
						</TabLink>
						<TabLink
							onClick={() => {
								setQueryString('period=month');
								setPeriod('month');
							}}
							active={period === 'month'}
						>
							This Month
						</TabLink>
						<TabLink
							onClick={() => {
								setQueryString('period=all');
								setPeriod('all');
							}}
							active={period === 'all'}
						>
							All
						</TabLink>
					</div>
					<Button className="font-bold" onClick={handleOpenFeedbackReport}>
						Feedback Report
					</Button>
				</div>
				<Table
					headings={headings}
					data={data?.data}
					refetchData={() => getSurveySubmissions(queryString)}
				/>
			</main>
			<SlideOver open={surveyState.commentsOpen} onClose={handleCloseComments}>
				<CommentPanel
					id={surveyState.commentSurveyId}
					onClose={handleCloseComments}
					createActivity={(payload) => createSurveySubmissionActivity(payload)}
					updateActivity={updateSurveySubmissionActivity}
					deleteActivity={deleteSurveySubmissionActivity}
					activityData={activityData}
					getActivity={getSurveySubmissionActivity}
					activityType="survey_submission"
					hasPermissionAccess={hasManagerAccess}
				/>
			</SlideOver>
			<SlideOver
				open={surveyState.surveyPanelOpen}
				onClose={handleCloseSurveyPanel}
			>
				<SurveyResultsPanel />
			</SlideOver>
			{/* Search Panels */}
			<Search open={clientOpen} setOpen={setClientOpen} searchHeading="Clients">
				<ClientFeedbackSearchBar
					setFilters={setQueryString}
					setOpen={setClientOpen}
				/>
			</Search>
			<Search
				open={teamLeadsOpen}
				setOpen={setTeamLeadsOpen}
				searchHeading="Team Lead"
			>
				<LeadFeedbackSearchBar
					setOpen={setTeamLeadsOpen}
					setFilters={setQueryString}
				/>
			</Search>
			<Search
				open={trafficControllerOpen}
				setOpen={setTrafficControllerOpen}
				searchHeading="Traffic Controller"
			>
				<TrafficControllerFeedbackSearchBar
					setOpen={setTeamLeadsOpen}
					setFilters={setQueryString}
				/>
			</Search>
		</MainLayout>
	);
};

export default Surveys;
