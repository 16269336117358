const hierachyOfControlData = [
	{
		label: 'Elimination',
		name: 'elimination',
		is_selected: false,
		description: '',
	},
	{
		label: 'Substitution',
		name: 'substitution',
		is_selected: false,
		description: '',
	},
	{
		label: 'Isolation',
		name: 'isolation',
		is_selected: false,
		description: '',
	},
	{
		label: 'Engineering',
		name: 'engineering',
		is_selected: false,
		description: '',
	},
	{
		label: 'Administration',
		name: 'administration',
		is_selected: false,
		description: '',
	},
	{
		label: 'PPE&C',
		name: 'ppe&c',
		is_selected: false,
		description: '',
	},
];

export { hierachyOfControlData };
