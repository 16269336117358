import React, { ReactElement, Fragment } from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from 'react-router-dom';
import Login from 'pages/login';
import ProtectedRoute from 'utils/ProtectedRoute';
import Incidents from 'pages/incidents';
import Links from 'pages/links';
import Surveys from 'pages/surveys';
import Users from 'pages/users';
import VehicleFaults from 'pages/faults';
import SurveysSubmission from 'pages/surveysSubmission';
import Unsubscribe from 'pages/unsubscribe';

const AppRoutes: React.FC = (): ReactElement => {
	return (
		<Router>
			<Fragment>
				<Routes>
					<Route path="/" element={<Navigate to={'/login'} />} />
					<Route path="/login" element={<Login />} />
					<Route
						path="surveys/submission/:uid"
						element={<SurveysSubmission />}
					/>
					<Route path="unsubscribe" element={<Unsubscribe />} />
					<Route element={<ProtectedRoute />}>
						<Route path="/vehicle-faults" element={<VehicleFaults />}>
							<Route path=":uid" element={<VehicleFaults />} />
						</Route>
						<Route path="/incidents" element={<Incidents />} />
						<Route path="/links" element={<Links />} />
						<Route path="/surveys" element={<Surveys />}>
							<Route path=":uid" element={<Surveys />} />
						</Route>
						<Route path="/users" element={<Users />} />
					</Route>
				</Routes>
			</Fragment>
		</Router>
	);
};

export default AppRoutes;
