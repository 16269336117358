import { baseAPI } from './baseAPI';

interface IUnsubscribeUserPayload {
	email: string;
}

export const unsubscribeAPI = baseAPI.injectEndpoints({
	endpoints: (builder) => ({
		unsubscribeUser: builder.mutation<void, IUnsubscribeUserPayload>({
			query(payload) {
				return {
					url: '/unsubscribed-users',
					method: 'POST',
					body: { data: payload },
				};
			},
		}),
	}),
});

export const { useUnsubscribeUserMutation } = unsubscribeAPI;
