import React, { SetStateAction, useEffect, useState } from 'react';

export interface IRadio {
	options: { title: string; id: string }[];
	defaultId?: string;
	onChange: React.Dispatch<SetStateAction<any>>;
	className?: string;
	classNameWrapper?: string;
	radioDirection?: 'flex-row' | 'flex-col';
}

const Radio: React.FC<IRadio> = ({
	options,
	defaultId,
	onChange,
	className = '',
	classNameWrapper = '',
	radioDirection = 'flex-col',
}) => {
	const [selected, setSelected] = useState(defaultId);

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		onChange(value);
		setSelected(value);
	};

	useEffect(() => {
		if (defaultId) setSelected(defaultId);
	}, [defaultId]);

	return (
		<form>
			<div className={`${className}`}>
				<fieldset>
					<div
						className={`flex ${radioDirection} w-full space-y-4 ${classNameWrapper} items-baseline`}
					>
						{selected &&
							options.map((option) => (
								<label
									key={option.id}
									className="flex items-center text-sm text-secondary cursor-pointer"
								>
									<input
										id={option.id}
										name="radio"
										type="radio"
										value={option.id}
										onChange={handleOnChange}
										checked={option.id === selected}
										className="focus:ring-primary-500 h-[18px] w-[18px] text-primary-500 bg-white mr-4 border-gray-300 rounded-full"
									/>
									{option.title}
								</label>
							))}
					</div>
				</fieldset>
			</div>
		</form>
	);
};

export default Radio;
