export const surveyTypeOptions = [
	{
		id: 'clients',
		label: 'Clients',
	},
	{
		id: 'team leads',
		label: 'Team Leads',
	},
	{
		id: 'traffic controllers',
		label: 'Traffic Controllers',
	},
];

export type SurveyQuestionTypes =
	| 'questions.free-text'
	| 'questions.multi-choice'
	| 'questions.multiple-choice'
	| 'questions.true-false';

export interface IVehicleSurveySubmissions {
	id: number;
	uid: string;
	first_name: string;
	last_name: string;
	email: string;
	survey_status: 'submitted' | 'open' | 'closed';
	type: 'survey';
	assignee: {
		id: string;
		first_name: string;
		last_name: string;
	};
	branch: {
		id: string;
		name: string;
	};
	survey: {
		id: number;
		name: string;
		sent_at: string;
		survey_type: 'Clients' | 'Traffic Controllers' | 'Team Leads';
	};
	managers: {
		id: string;
		first_name: string;
		last_name: string;
	}[];
}

export interface IVehicleSurveySubmissionResponse {
	id: number;
	first_name: string;
	last_name: string;
	survey_status: 'open' | 'submitted' | 'close';
	survey_results: {
		id: number;
		question: string;
		answer: string;
	}[];
	survey: {
		id: number;
		name: string;
		questions: {
			id: number;
			question: string;
			type: SurveyQuestionTypes;
			options: {
				id: number;
				option: string;
			}[];
		}[];
	};
}

export interface IFilters {
	[name: string]: string | null;
}

export const initialClientFilterState: IFilters = {
	company: '',
	client: '',
	from: null,
	end: null,
	dateType: 'responded',
	state: '',
	branch: '',
};

export const initialLeadsFilterState: IFilters = {
	employee: '',
	from: null,
	end: null,
	dateType: 'responded',
	state: '',
	branch: '',
};

export const initialTrafficControllerFilterState: IFilters = {
	employee: '',
	from: null,
	end: null,
	dateType: 'responded',
	state: '',
	branch: '',
};

export const stateOptions = [
	{
		id: 'nsw',
		label: 'NSW',
	},
	{
		id: 'vic',
		label: 'VIC',
	},
	{
		id: 'qld',
		label: 'QLD',
	},
	{
		id: 'wa',
		label: 'WA',
	},
	{
		id: 'sa',
		label: 'SA',
	},
	{
		id: 'tas',
		label: 'TAS',
	},
];
