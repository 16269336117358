import { EyeIcon } from '@heroicons/react/outline';
import Button from 'components/atoms/Button';
import Divider from 'components/atoms/Divider';
import Heading from 'components/atoms/Heading';
import Radio from 'components/atoms/Radio';
import Select from 'components/atoms/Select';
import Text from 'components/atoms/Text';
import React, { useState } from 'react';
import { useAssignSurveySubmissionMutation } from 'redux/api/surveys';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { openCommentModal, openSurveyModal } from 'redux/slices/surveys';
import { IVehicleSurveySubmissions } from 'types/Surveys';

export interface ISurveyCard {
	className?: string;
	survey: IVehicleSurveySubmissions;
}

const SurveyCard: React.FC<ISurveyCard> = ({ survey }) => {
	const [assignUser] = useAssignSurveySubmissionMutation();
	const userPermission = useAppSelector((state) => state.user.permissions);
	const dispatch = useAppDispatch();
	const [status, setStatus] = useState(survey.survey_status || 'submitted');
	const [assignee, setAssignee] = useState(survey.assignee?.id);

	const hasWriteAccess = () => {
		const permission = userPermission?.filter(
			(permission) =>
				permission?.branch?.id === survey?.branch?.id &&
				permission.survey_level === 'write'
		);

		if (!permission) {
			return false;
		}

		return permission.length > 0;
	};

	const handleOpenComments = () => {
		const payload = {
			commentSurveyId: survey.id,
			surveyBranchId: survey?.branch?.id,
		};

		dispatch(openCommentModal(payload));
	};

	const handleSurveyResults = () => {
		dispatch(openSurveyModal(survey.uid));
	};

	const handleUpdateSurveySubmission = () => {
		let assignId = null;
		survey.managers.forEach((manager) => {
			if (assignee === `${manager.first_name} ${manager.last_name}`) {
				assignId = parseInt(manager.id);
			}
		});

		assignUser({
			id: survey.id,
			body: {
				assignee: assignId,
				survey_status: status,
			},
		});
	};

	return (
		<div className="w-full pl-10 pr-10 pb-10">
			<Divider />
			<div className="grid gap-10 grid-col grid-cols-2 xl:grid-cols-3 space-y-2 my-6">
				<div>
					<Heading type="h3" className="mb-6">
						Overview
					</Heading>
					<div className="flex flex-row gap-16 my-6">
						<Text className="2xl:min-w-[200px]">Summary Details</Text>
						<Text>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
							tincidunt purus sit amet nisl consectetur, ut dignissim purus
							laoreet. Nullam id semper nibh.
						</Text>
					</div>
					<Divider />
					<div className="flex flex-row gap-16 my-6">
						<Text className="2xl:min-w-[200px]">Survey Type</Text>
						<Text>{survey?.survey.survey_type}</Text>
					</div>
					<Divider />
					<div className="flex flex-row gap-16 my-6">
						<Text className="2xl:min-w-[200px]">Average Rating</Text>
						<Text>4.5</Text>
					</div>
					<Divider />
					<div className="flex flex-row gap-16 my-6">
						<Text className="2xl:min-w-[200px]">Completion Time</Text>
						<Text>15/07/22 1.41pm (time to action: 3.44hrs)</Text>
					</div>
					<Divider />
					<Button
						size="lg"
						className="mt-6 font-bold"
						onClick={handleSurveyResults}
					>
						Survey Results
					</Button>
				</div>

				<div className="hidden xl:block">
					<Heading type="h3" className="mb-6">
						Details
					</Heading>
					<div className="flex flex-row gap-16 my-6">
						<Text className="2xl:min-w-[200px]">Job No.</Text>
						<Text>756086</Text>
					</div>
					<Divider />
					<div className="flex flex-row gap-16 my-6">
						<Text className="2xl:min-w-[200px]">Recipient</Text>
						<div>
							<Text>{`${survey.first_name} ${survey.last_name}`}</Text>
							<Text>{`${survey.email}`}</Text>
						</div>
					</div>
					<Divider />
					<div className="flex flex-row gap-16 my-6">
						<Text className="2xl:min-w-[200px]">Company</Text>
						<Text>AECOM Services Pty Ltd</Text>
					</div>
					<Divider />
					<div className="flex flex-row gap-16 my-6">
						<Text className="2xl:min-w-[200px]">Email notification</Text>
						<Text>Yes</Text>
					</div>
					<Divider />
					<div className="flex flex-row gap-16 my-6">
						<Text className="2xl:min-w-[200px]">Created by</Text>
						<Text>
							Andrew Gentile: 15/07/22 1.41pm (time to action: 3.44hrs)
						</Text>
					</div>
					<Divider />
				</div>

				<div>
					<div className="max-w-sm w-full">
						<Heading type="h3" className="mb-6">
							Comments
						</Heading>
						<Button
							type="quaternary"
							className="mr-5 font-bold"
							size="lg"
							onClick={handleOpenComments}
						>
							<EyeIcon className="h-5 w-5 mr-1" />
							View Comments
						</Button>
						{hasWriteAccess() && (
							<>
								<div className="h-0 border-t-[1px] border-primary-200 w-full my-5" />
								<Heading type="h2" className="mb-6">
									Assign to
								</Heading>

								<Select
									options={survey?.managers.map((manager) => {
										return {
											id: manager.id,
											label: `${manager.first_name} ${manager.last_name}`,
										};
									})}
									selected={assignee}
									onChange={setAssignee}
									placeholder="Select name"
									className="w-full min-w-[200px]"
								/>
								<Heading type="h2" className="mt-4">
									Status
								</Heading>
								<Radio
									className="mt-5 mb-7"
									options={[
										{ title: 'Open', id: 'open' },
										{ title: 'Close', id: 'closed' },
									]}
									defaultId={status}
									onChange={setStatus}
									radioDirection="flex-row"
									classNameWrapper="gap-4"
								/>

								<Button
									navigate="next"
									className="font-bold"
									size="lg"
									onClick={handleUpdateSurveySubmission}
								>
									Submit
								</Button>
							</>
						)}
					</div>
				</div>
			</div>

			<div className="mt-10 w-full xl:hidden">
				<Heading type="h3">Details</Heading>
				<div className="grid gap-10 grid-col grid-cols-2">
					<div>
						<div className="flex flex-row gap-16 my-6">
							<Text>Job No.</Text>
							<Text>756086</Text>
						</div>
						<Divider />
						<div className="flex flex-row gap-16 my-6">
							<Text>Recipient</Text>
							<div>
								<Text>{`${survey.first_name} ${survey.last_name}`}</Text>
								<Text>{`${survey.email}`}</Text>
							</div>
						</div>
						<Divider />
					</div>

					<div>
						<div className="flex flex-row gap-16 my-6">
							<Text>Company</Text>
							<Text>AECOM Services Pty Ltd</Text>
						</div>
						<Divider />
						<div className="flex flex-row gap-16 my-6">
							<Text>Email notification</Text>
							<Text>Yes</Text>
						</div>
						<Divider />
					</div>

					<div className="flex flex-row gap-16 my-6">
						<Text>Created by</Text>
						<Text>
							Andrew Gentile: 15/07/22 1.41pm (time to action: 3.44hrs)
						</Text>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SurveyCard;
