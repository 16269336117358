import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFilters, initialFilterState } from 'types/VehicleFaults';

export interface VehicleFaultState {
	commentsOpen: boolean;
	commentFaultId: number | null;
	vehiclefaultId: number | null;
	faultBranchId: string | null;
	vehicleFaultPanelOpen: boolean;
	filters: IFilters;
}

export interface VehicleFaultReportState {
	vehiclefaultId?: number;
}

export interface CommentPayload {
	commentFaultId: number | null;
	faultBranchId: string | null;
}

const initialState: VehicleFaultState = {
	commentsOpen: false,
	commentFaultId: null,
	faultBranchId: null,
	vehiclefaultId: null,
	vehicleFaultPanelOpen: false,
	filters: initialFilterState,
};

const vehicleFaultSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		openCommentModal(state, action: PayloadAction<CommentPayload>) {
			const payload = action.payload;
			state.commentFaultId = payload.commentFaultId;
			state.faultBranchId = payload.faultBranchId;
			state.commentsOpen = true;
		},
		closeCommentModal(state) {
			state.commentFaultId = null;
			state.faultBranchId = null;
			state.commentsOpen = false;
		},
		updateFilterState(state, action: PayloadAction<IFilters>) {
			state.filters = action.payload;
		},
		resetFilterState(state) {
			state.filters = initialFilterState;
		},
		openFaultModal(state) {
			state.vehicleFaultPanelOpen = true;
		},
		closeFaultModal(state) {
			state.vehiclefaultId = null;
			state.vehicleFaultPanelOpen = false;
		},
	},
});

export const {
	openCommentModal,
	closeCommentModal,
	updateFilterState,
	openFaultModal,
	closeFaultModal,
	resetFilterState,
} = vehicleFaultSlice.actions;
export default vehicleFaultSlice.reducer;
