import React, { SetStateAction } from 'react';
import AltusLogo from 'assets/images/AltusLogo.svg';
import { INavigation } from 'types/Layout';
import CustomIcon from 'components/atoms/CustomIcon';
import Text from 'components/atoms/Text';

export interface ISideBar {
	navigation: INavigation[];
	setSelected: React.Dispatch<SetStateAction<INavigation>>;
}

const SideBar: React.FC<ISideBar> = ({ navigation, setSelected }) => {
	const navLinkHover =
		'hover:bg-primary-500 hover:border-primary-500 hover:text-primary justify-center m-auto';

	const navLinkActive =
		'active:bg-[#ffd898] active:border-[#ffd898] active:text-primary justify-center m-auto';

	const navLinkDisabled =
		'disabled:bg-white disabled:border disabled:border-secondary-50 disabled:text-secondary-50 justify-center m-auto';

	return (
		<div className="flex flex-col flex-start w-[113px] h-full shadow-xl z-50 bg-white">
			<div className="h-[108px] mx-auto p-6">
				<img
					className="h-[62px] w-auto m-auto"
					src={AltusLogo}
					alt="AltusLogo"
				/>
			</div>
			<div className="flex flex-col w-full gap-y-6 pt-4 pb-4">
				{navigation.map((item: INavigation) => (
					<div
						onClick={() => setSelected(item)}
						className="cursor-pointer"
						key={item.name}
					>
						<div className="flex flex-col gap-2">
							<div
								className={`
									${navLinkHover}
									${navLinkActive}
									${navLinkDisabled}
									align-middle
									m-auto
									rounded-md
									h-[42px]
									w-[42px]
								`}
							>
								<CustomIcon
									iconTypes={item.name}
									iconState={item.current ? 'active' : 'unselected'}
								/>
							</div>
							<Text className="mx-auto">{item.name}</Text>
							{item.total && (
								<div className="flex items-center justify-center border-primary-500 w-[38px] mx-auto rounded-2xl border-[1.5px] px-6 py-0.5">
									<Text className="text-primary-500">{item.total}</Text>
								</div>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default SideBar;
