import Text from 'components/atoms/Text';
import React, { useState, useRef, useEffect } from 'react';
import DefaultLogo from 'assets/images/default-avatar.png';
import { TrashIcon, PencilIcon, CheckIcon } from '@heroicons/react/outline';
import WarningPopup from 'components/molecules/WarningPopup';

export interface IActivityCard {
	type: 'comment' | 'status' | 'assign';
	content: string;
	user: string;
	id: number;
	avatar?: string;
	date: Date | undefined;
	className?: string;
	handleDeleteComment(id: number): void;
	handleEditComment(activityId: number, comment: string): void;
	hasWriteAccess: () => boolean | undefined;
}

const ActivityCard: React.FC<IActivityCard> = ({
	type,
	content,
	user,
	avatar,
	date,
	id,
	handleDeleteComment,
	handleEditComment,
	hasWriteAccess,
	className = '',
}) => {
	const [isDisabledComment, setIsDisabledComment] = useState(true);
	const [comment, setComment] = useState(content);
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const [showPopup, setShowPopup] = useState(false);

	const formatedDate = () => {
		if (!date) {
			return '';
		}
		const rawDate = new Date(date);

		return rawDate.toLocaleDateString('en-AU', {
			year: '2-digit',
			month: '2-digit',
			day: '2-digit',
			hour12: true,
			hour: '2-digit',
			minute: 'numeric',
		});
	};

	useEffect(() => {
		textAreaRef.current?.focus();
	}, [isDisabledComment]);

	useEffect(() => {
		if (textAreaRef.current) {
			const scrollHeight = textAreaRef.current.scrollHeight;
			textAreaRef.current.style.height = `${scrollHeight}px`;
		}
	}, [comment]);

	const handleCommentChange = () => {
		setIsDisabledComment((prevState) => !prevState);
		if (!isDisabledComment) {
			handleEditComment(id, comment);
		}
	};

	return (
		<>
			<div className={`bg-gray-200 relative space-y-4 p-4 ${className}`}>
				<div className={`flex bg-transparent ${className}`}>
					<img
						src={avatar ? avatar : DefaultLogo}
						alt=""
						className="inline-block h-12 w-12 rounded-full"
					/>
					<div className="ml-4 flex flex-col">
						<Text type="bold" className="flex-1">
							{user}
						</Text>
						<Text className="text-xs justify-end">{formatedDate()}</Text>
					</div>
				</div>

				{type === 'assign' && (
					<div className="flex space-x-2">
						<Text type="bold">Action:</Text>
						<Text color="text-primary">{content}</Text>
					</div>
				)}

				{type === 'status' && (
					<div className="flex space-x-2">
						<Text type="bold">Action:</Text>
						<Text color="text-primary">{content}</Text>
					</div>
				)}

				{type === 'comment' && (
					<div className="bg-white p-2 rounded-sm flex flex-row justify-between">
						<textarea
							className="pr-3 border-none focus:ring-gray-300 focus:bg-primary-50 focus:border-primary text-secondary-500 text-sm font-inter w-full resize-none min-h-min"
							value={comment}
							ref={textAreaRef}
							disabled={isDisabledComment}
							onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
								setComment(e.target.value)
							}
							rows={1}
						/>
						{hasWriteAccess() && (
							<div className="border-l border-gray-400 px-3 flex flex-col justify-around gap-2">
								{isDisabledComment ? (
									<PencilIcon
										height={16}
										width={16}
										className="text-secondary-500 cursor-pointer"
										onClick={handleCommentChange}
									/>
								) : (
									<CheckIcon
										height={16}
										width={16}
										className="cursor-pointer text-green-600"
										onClick={handleCommentChange}
									/>
								)}

								<TrashIcon
									height={16}
									width={16}
									className="text-secondary-500 cursor-pointer"
									onClick={() => {
										setShowPopup(true);
									}}
								/>
							</div>
						)}
					</div>
				)}
				<WarningPopup
					header="Delete Comment"
					content="Are you sure you want to delete this comment?"
					showModal={showPopup}
					setShowModal={setShowPopup}
					handleDeleteItem={handleDeleteComment}
					id={id}
				/>
			</div>
		</>
	);
};

export default ActivityCard;
