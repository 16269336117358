import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetUser } from 'types/User';

export interface UserState {
	id?: number;
	token?: string;
	avatar?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	permissions?: {
		branch: { id: string };
		role: 'staff' | 'manager';
		incident_level: 'read' | 'write' | null;
		fault_level: 'read' | 'write' | null;
		survey_level: 'read' | 'write' | null;
	}[];
}

const initialState: UserState = {
	id: undefined,
	token: undefined,
	firstName: undefined,
	lastName: undefined,
	email: undefined,
	avatar: undefined,
	permissions: undefined,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		loadUser(state, action: PayloadAction<IGetUser>) {
			const payload = action.payload;
			state.id = payload.id;
			state.firstName = payload.first_name;
			state.lastName = payload.last_name;
			state.avatar = payload.avatar?.url;
			state.permissions = payload.permissions;
		},
		logoutUser() {
			localStorage.removeItem('accessToken');
			return initialState;
		},
	},
});

export const { loadUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;
