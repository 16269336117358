import React from 'react';
import Text from 'components/atoms/Text';

export interface IToggleButton {
	containerClassName?: string;
	className?: string;
	toggle: boolean;
	setToggle: React.Dispatch<React.SetStateAction<boolean>>;
	sectionLabel?: string;
	label?: string;
	required?: boolean;
}

const ToggleButton: React.FC<IToggleButton> = ({
	containerClassName = '',
	className = '',
	toggle = false,
	setToggle,
	sectionLabel = '',
	label = '',
	required = false,
}) => {
	const backgroundColor = toggle
		? 'bg-green border-transparent'
		: 'bg-transparent border-secondary';
	const translateDot = toggle
		? 'translate-x-12 bg-white'
		: 'translate-x-1 bg-secondary';
	const translateText = toggle
		? 'text-white ml-2 translate-x-2'
		: 'text-secondary translate-x-8';

	return (
		<div className={`flex my-8 ${containerClassName}`}>
			{label && (
				<Text className=" text-secondary mr-6" type="bold">
					{label} {required && <span className="text-red">*</span>}
				</Text>
			)}

			<div
				onClick={() => setToggle(!toggle)}
				className={`${backgroundColor} ${className} relative inline-flex flex-shrink-0 h-[30px] w-[76px] border-2  
			rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green`}
			>
				<div className={`${translateText} font-bold`}>
					{toggle ? 'Yes' : 'No'}
				</div>
				<span
					className={`${translateDot} pointer-events-none inline-block h-5 w-5 rounded-full shadow transform ring-0 transition ease-in-out duration-200 absolute left-[1px] top-[3px]`}
				></span>
			</div>
		</div>
	);
};

export default ToggleButton;
