import Button from 'components/atoms/Button';
import DateInput from 'components/atoms/DateInput';
import Heading from 'components/atoms/Heading';
import Input from 'components/atoms/Input';
import Select from 'components/atoms/Select';
import Text from 'components/atoms/Text';
import VehicleFault from 'components/molecules/VehicleFault';
import React, { useEffect, useState } from 'react';
import { PlusIcon, ChevronRightIcon } from '@heroicons/react/outline';
import Radio from 'components/atoms/Radio';
import {
	vehicleFaultRadioButtons,
	vehicleTypeOptions,
	vehiclePriorityOptions,
} from 'types/VehicleFaults';
import Divider from 'components/atoms/Divider';
import { useAppSelector } from 'redux/hooks';
import { useGetBranchesQuery } from 'redux/api/vehicleIncidents';
import { formatDate } from 'utils/formatDate';
import {
	useCreateVehicleFaultMutation,
	useGetVehicleQuestionsQuery,
	useGetBranchMutation,
} from 'redux/api/vehicleFaults';
import { useAppDispatch } from 'redux/hooks';
import { closeFaultModal } from 'redux/slices/vehicleFaultSlice';

export interface IVehiclePanel {
	className?: string;
}

export interface IVehiclePaneInitialState {
	ticket_number: string;
	registration: string;
	vehicle_type: string;
	assignee: string;
	status: string;
	description: string;
	branch_options: {
		id: string;
		label: string;
	}[];
	faults: {
		id: number;
		fault_type: string;
		priority: string;
	}[];
	incident_date: Date | null;
}

const initialState: IVehiclePaneInitialState = {
	ticket_number: '',
	registration: '',
	vehicle_type: '',
	assignee: '',
	status: 'open',
	description: '',
	branch_options: [],
	faults: [
		{
			id: 0,
			fault_type: '',
			priority: '',
		},
	],
	incident_date: null,
};

const initialErrorState = {
	ticket_number: false,
	registration: false,
	vehicle_type: false,
	assignee: false,
	description: false,
	faults: false,
	branch: false,
	incident_date: false,
};

const VehiclePanel: React.FC<IVehiclePanel> = () => {
	const [vehicleFault, setVehicleFault] = useState(initialState);
	const [branchName, setBranchName] = useState('');
	const userState = useAppSelector((state) => state.user);
	const { data: branchData, isSuccess: branchIsSuccess } =
		useGetBranchesQuery();
	const { data: faultTypeData, isSuccess: faultTypeIsSuccess } =
		useGetVehicleQuestionsQuery();
	const [createVehicleFault] = useCreateVehicleFaultMutation();
	const [getBranchUsers] = useGetBranchMutation();
	const [assignOptions, setAssignOptions] = useState([
		{
			id: '',
			label: '',
		},
	]);
	const [faultTypeOptions, setFaultTypeOptions] = useState([
		{
			id: '',
			label: '',
		},
	]);
	const [errorState, setErrorState] = useState(initialErrorState);
	const dispatch = useAppDispatch();

	const handlevehicleFaultUpdate = (
		property: string,
		value: string | Date,
		index?: number
	) => {
		const copyVehicleFault: IVehiclePaneInitialState = vehicleFault;
		const copyFaults = [...copyVehicleFault.faults];
		if (
			typeof value === 'string' &&
			(property === 'description' ||
				property === 'ticket_number' ||
				property === 'vehicle_type' ||
				property === 'assignee' ||
				property === 'status' ||
				property === 'registration')
		) {
			copyVehicleFault[property] = value;
		} else if (typeof value === 'object' && property === 'incident_date') {
			copyVehicleFault[property] = value;
		} else if (
			typeof value === 'string' &&
			(property === 'fault_type' || property === 'priority')
		) {
			if (index !== undefined) {
				copyFaults[index] = {
					...copyFaults[index],
					[property]: value,
				};
			}
		}
		copyVehicleFault.faults = copyFaults;
		setVehicleFault(copyVehicleFault);
	};

	useEffect(() => {
		if (faultTypeIsSuccess && faultTypeData) {
			setFaultTypeOptions(faultTypeData.dropdownData[0].fault_options);
		}
	}, [faultTypeIsSuccess, faultTypeData]);

	useEffect(() => {
		if (branchIsSuccess && branchData) {
			setVehicleFault((preVehicleFault) => {
				return {
					...preVehicleFault,
					branch_options: branchData,
				};
			});
		}
	}, [branchIsSuccess, branchData]);

	useEffect(() => {
		if (branchName !== '') {
			const fetchBranchUsers = async () => {
				const getBranch = vehicleFault.branch_options.filter(
					(branch) => branch.label === branchName
				);
				const branchId = parseInt(getBranch[0]?.id);
				if (!branchId) {
					return;
				}

				const users = await getBranchUsers(branchId).catch((err) => {
					throw new Error(err);
				});

				setAssignOptions(
					'data' in users
						? users?.data.user_permissions
						: [
								{
									id: '',
									label: '',
								},
						  ]
				);
			};
			fetchBranchUsers();
		}
	}, [branchName, getBranchUsers, vehicleFault.branch_options]);

	const addFault = () => {
		const { faults } = vehicleFault;
		const assignId = faults[faults.length - 1].id + 1;

		setVehicleFault((fault) => {
			return {
				...fault,
				faults: [
					...fault.faults,
					{
						id: assignId,
						fault_type: '',
						priority: '',
					},
				],
			};
		});
	};

	const deleteFault = (id: number) => {
		setVehicleFault((prevVehicleFaults) => {
			return {
				...prevVehicleFaults,
				faults: vehicleFault.faults.filter((fault) => fault.id !== id),
			};
		});
	};

	const onSubmit = async () => {
		const {
			ticket_number,
			assignee,
			incident_date,
			registration,
			vehicle_type,
			description,
			faults,
			status,
		} = vehicleFault;
		const allFieldsRequired = [
			ticket_number,
			registration,
			vehicle_type,
			description,
			faults.length > 0,
			branchName,
			assignee,
			incident_date !== null,
		].every(Boolean);

		if (allFieldsRequired) {
			let assignId = null;
			assignOptions.forEach((option) => {
				if (option.label === vehicleFault.assignee) {
					assignId = parseInt(option.id);
				}
			});

			let branchId = null;
			branchData?.forEach((branch) => {
				if (branch.label === branchName) {
					branchId = parseInt(branch.id);
				}
			});

			const faultPayload = {
				ticket_number,
				incidentDate: formatDate(incident_date),
				registration,
				vehicleType: vehicle_type,
				branch: branchId,
				description,
				faults,
				assignee: assignId,
				status,
			};

			await createVehicleFault(faultPayload).catch((err) => {
				throw new Error(err);
			});

			dispatch(closeFaultModal());
		} else {
			setErrorState({
				...errorState,
				ticket_number: ticket_number === '',
				registration: registration === '',
				vehicle_type: vehicle_type === '',
				assignee: assignee === '',
				description: description === '',
				faults: faults[0].fault_type === '' || faults[0].priority === '',
				branch: branchName === '',
				incident_date: !incident_date,
			});
		}
	};

	return (
		<form className="mx-4">
			<Heading type="h2" className="mb-8">
				New Vehicle Fault
			</Heading>
			<div className="flex justify-between gap-4 flex-wrap my-10">
				<div>
					<Input
						type="text"
						onChange={(value: string) =>
							handlevehicleFaultUpdate('ticket_number', value)
						}
						value={vehicleFault.ticket_number}
						className="w-80"
						required={true}
						label="Ticket Number"
						placeholder="Type"
						errorMessage={
							errorState.ticket_number ? 'Please enter a ticket number' : ''
						}
					/>
				</div>
				<div>
					<Input
						type="text"
						value={`${userState.firstName} ${userState.lastName}`}
						className="w-80"
						required={true}
						label="Creator"
						isDisabled={true}
					/>
				</div>
				<div>
					<DateInput
						placeholder="Select Date"
						onChange={(value: Date) =>
							handlevehicleFaultUpdate('incident_date', value)
						}
						className="w-80"
						label="Incident Date"
						required={true}
						iconClassName="text-black"
						selected={vehicleFault.incident_date}
						wrapperClassName="react-datepicker-margin-0"
						errorMessage={
							errorState.incident_date ? 'Please enter an incident date' : ''
						}
					/>
				</div>
			</div>
			<div className="flex justify-between gap-4 flex-wrap my-8">
				<div>
					<Input
						type="text"
						onChange={(value: string) =>
							handlevehicleFaultUpdate('registration', value)
						}
						value={vehicleFault.registration}
						className="w-80"
						required={true}
						label="Registration"
						placeholder="Vehicle Registration Plates"
						errorMessage={
							errorState.registration ? 'Please enter a registration' : ''
						}
					/>
				</div>
				<div>
					<Select
						label="Vehicle Type"
						options={vehicleTypeOptions}
						onChange={(value: string) =>
							handlevehicleFaultUpdate('vehicle_type', value)
						}
						className="w-80"
						required={true}
						selected={vehicleFault.vehicle_type}
						errorMessage={
							errorState.vehicle_type ? 'Please enter a vehicle type' : ''
						}
					/>
				</div>
				<div>
					<Select
						label="Branch"
						options={vehicleFault.branch_options}
						onChange={(value: string) => setBranchName(value)}
						className="w-80"
						required={true}
						selected={branchName}
						errorMessage={errorState.branch ? 'Please enter a branch' : ''}
					/>
				</div>
			</div>
			<div className="bg-white shadow-lg rounded-lg">
				<div className="p-6">
					<Input
						type="text"
						onChange={(value: string) =>
							handlevehicleFaultUpdate('description', value)
						}
						value={vehicleFault.description}
						className="w-full pb-6"
						required={true}
						label="Fault Description"
						placeholder="Type"
						errorMessage={
							errorState.description ? 'Please enter a fault description' : ''
						}
					/>
					{vehicleFault.faults.map((fault, index) => {
						const { id } = fault;
						return (
							<VehicleFault
								key={id}
								deleteFault={deleteFault}
								fault={fault}
								id={id}
								index={index}
								faultOptions={faultTypeOptions}
								priorityOptions={vehiclePriorityOptions}
								handleVehicleFaultUpdate={handlevehicleFaultUpdate}
							/>
						);
					})}
					{errorState.faults && (
						<Text className="text-red-500">
							Please complete at least one fault *
						</Text>
					)}
					<Button type="secondary" className="mt-6 py-4" onClick={addFault}>
						<PlusIcon height={18} width={18} className="mr-2" />
						Another Fault
					</Button>
				</div>
			</div>
			<div className="flex gap-4 flex-wrap my-10">
				<div>
					<Select
						label="Assign to"
						options={assignOptions}
						onChange={(value: string) =>
							handlevehicleFaultUpdate('assignee', value)
						}
						className="w-80"
						required={true}
						selected={vehicleFault.assignee}
						errorMessage={errorState.assignee ? 'Please assign a user' : ''}
					/>
				</div>
				<div>
					<Text type="bold">Status</Text>
					<Radio
						options={vehicleFaultRadioButtons}
						onChange={(value: string) =>
							handlevehicleFaultUpdate('status', value)
						}
						defaultId={vehicleFault.status}
						className="mt-2 mb-2"
						classNameWrapper="gap-4 items-center"
						radioDirection="flex-row"
					/>
				</div>
			</div>
			<Divider />
			<Button type="primary" className="font-bold mt-6 py-3" onClick={onSubmit}>
				Save & Submit
				<ChevronRightIcon width={18} height={18} className="ml-1" />
			</Button>
		</form>
	);
};

export default VehiclePanel;
