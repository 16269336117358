import CheckBox from 'components/atoms/CheckBox';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { updateFilterState } from 'redux/slices/vehicleFaultSlice';
import { IOption } from 'types/VehicleFaults';
import { updateIncidentFilterState } from 'redux/slices/vehicleIncidentsSlice';
export interface ICheckBoxGroup {
	filterName:
		| 'branch'
		| 'vehicle'
		| 'priority'
		| 'status'
		| 'type'
		| 'client'
		| 'severity';
	filterType: 'incident' | 'fault';
}
const CheckBoxGroup: React.FC<ICheckBoxGroup> = ({
	filterName,
	filterType,
}) => {
	const dispatch = useAppDispatch();
	const filters = useAppSelector((state) => state.vehicleFault.filters);
	const incidentFilters = useAppSelector(
		(state) => state.vehicleIncident.filters
	);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const filterSection = filterType === 'fault' ? filters : incidentFilters;
		const filterState = JSON.parse(JSON.stringify(filterSection));

		const checkBoxItems = filterState[filterName] as IOption[];
		const index = checkBoxItems.findIndex(
			(item) => item.id === Number(e.target.id)
		);
		checkBoxItems[index].checked = e.target.checked;
		filterState[filterName] = checkBoxItems;
		if (filterType === 'fault') {
			dispatch(updateFilterState(filterState));
		}
		if (filterType === 'incident') {
			dispatch(updateIncidentFilterState(filterState));
		}
	};

	return (
		<div className="flex flex-col space-y-2">
			<>
				{filterType === 'fault' &&
					(filters[filterName] as IOption[]).map((item: IOption) => (
						<CheckBox key={item.id} item={item} onChange={onChange} />
					))}
			</>
			<>
				{filterType === 'incident' &&
					(incidentFilters[filterName] as IOption[]).map((item: IOption) => (
						<CheckBox key={item.id} item={item} onChange={onChange} />
					))}
			</>
		</div>
	);
};

export default CheckBoxGroup;
