import MainLayout from 'components/templates/MainLayout';
import { useEffect, useState } from 'react';
import Table from 'components/organisms/Table';
import Button from 'components/atoms/Button';
import { PlusIcon, SearchIcon } from '@heroicons/react/solid';
import TabLink from 'components/atoms/TabLink';
import { IQueryPeriod } from 'types/VehicleIncidents';
import SlideOver from 'components/molecules/SlideOver';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
	closeCommentModal,
	closeIncidentModal,
	openIncidentModal,
} from 'redux/slices/vehicleIncidentsSlice';
import CommentPanel from 'components/organisms/CommentPanel';
import IncidentPanel from 'components/organisms/IncidentPanel';
import Search from 'components/molecules/Search';
import {
	useGetVehicleIncidentsMutation,
	useGetIncidentActivityMutation,
	useCreateIncidentActivityMutation,
	useUpdateIncidentActivityMutation,
	useDeleteIncidentActivityMutation,
} from 'redux/api/vehicleIncidents';
import IncidentSearchBar from 'components/molecules/IncidentSearchBar';

const Incidents = () => {
	const [period, setPeriod] = useState<IQueryPeriod>('today');
	const [filters, setFilters] = useState<string>('period=today');
	const [open, setOpen] = useState(false);
	const [getIncidents, { data }] = useGetVehicleIncidentsMutation();
	const IncidentState = useAppSelector((state) => state.vehicleIncident);
	const dispatch = useAppDispatch();
	const userPermission = useAppSelector((state) => state.user.permissions);
	const [createIncidentActivity] = useCreateIncidentActivityMutation();
	const [deleteIncidentActivity] = useDeleteIncidentActivityMutation();
	const [updateIncidentActivity] = useUpdateIncidentActivityMutation();
	const [getIncidentActivity, { data: activityData }] =
		useGetIncidentActivityMutation();

	useEffect(() => {
		getIncidents(filters);
	}, [filters, getIncidents, IncidentState.incidentSection1Id]);
	const handleCloseComments = () => {
		dispatch(closeCommentModal());
	};

	const handleCloseIncidentReport = () => {
		dispatch(closeIncidentModal());
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const createNewIncident = () => {
		dispatch(
			openIncidentModal({ incidentReportId: null, isNewIncident: true })
		);
	};

	const hasManagerAccess = () => {
		const permission = userPermission?.filter(
			(permission) =>
				permission.role === 'manager' &&
				permission.branch?.id === IncidentState.incidentBranchId &&
				permission.incident_level === 'write'
		);

		if (!permission) {
			return false;
		}
		return permission.length > 0;
	};

	const headings = [
		'Branch',
		'Ticket No.',
		'Date',
		'Submitted By',
		'Client',
		'Severity',
		'Status',
	];
	return (
		<MainLayout url="incidents">
			<main className="">
				<div className="flex items-center justify-center py-7 px-4 sticky top-0 z-30 bg-white">
					<Button type="secondary" onClick={handleOpen}>
						<SearchIcon className="h-5 w-5 mr-1" />
						Search
					</Button>
					<div className="flex items-center justify-center flex-1 w-full space-x-4">
						<TabLink
							onClick={() => {
								setFilters('period=today');
								setPeriod('today');
							}}
							active={period === 'today'}
						>
							Today
						</TabLink>
						<TabLink
							onClick={() => {
								setFilters('period=week');
								setPeriod('week');
							}}
							active={period === 'week'}
						>
							This Week
						</TabLink>
						<TabLink
							onClick={() => {
								setFilters('period=month');
								setPeriod('month');
							}}
							active={period === 'month'}
						>
							This Month
						</TabLink>
						<TabLink
							onClick={() => {
								setFilters('period=all');
								setPeriod('all');
							}}
							active={period === 'all'}
						>
							All
						</TabLink>
					</div>
					<Button onClick={createNewIncident}>
						<PlusIcon className="h-5 w-5 mr-1" />
						New Report
					</Button>
				</div>
				<Table
					headings={headings}
					data={data?.data}
					refetchData={() => getIncidents(period)}
				/>
			</main>
			<Search open={open} setOpen={setOpen} searchType={'incident'}>
				<IncidentSearchBar setFilters={setFilters} setOpen={setOpen} />
			</Search>
			<SlideOver
				open={IncidentState.commentsOpen}
				onClose={handleCloseComments}
			>
				<CommentPanel
					id={IncidentState.commentIncidentId}
					onClose={handleCloseComments}
					createActivity={(payload) => createIncidentActivity(payload)}
					updateActivity={updateIncidentActivity}
					deleteActivity={deleteIncidentActivity}
					activityData={activityData}
					getActivity={getIncidentActivity}
					activityType="incident"
					hasPermissionAccess={hasManagerAccess}
				/>
			</SlideOver>
			<SlideOver
				open={IncidentState.incidentReportOpen}
				onClose={handleCloseIncidentReport}
			>
				<IncidentPanel onClose={handleCloseIncidentReport} />
			</SlideOver>
		</MainLayout>
	);
};

export default Incidents;
