import TabLink from 'components/atoms/TabLink';
import React, { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { IQueryReportSection } from 'types/VehicleIncidents';
import IncidentSection1 from 'components/organisms/IncidentSection1';
import IncidentSection2 from 'components/organisms/IncidentSection2';
import IncidentSection3 from 'components/organisms/IncidentSection3';
import IncidentSection4 from 'components/organisms/IncidentSection4';
import IncidentSection5 from 'components/organisms/IncidentSection5';

export interface IIncidentPanel {
	onClose: () => void;
}

const IncidentPanel: React.FC<IIncidentPanel> = () => {
	const [section, setSection] = useState<IQueryReportSection>('section1');
	const {
		incidentSection1Id,
		incidentSection2Id,
		incidentSection3Id,
		incidentSection4Id,
		incidentSection5Id,
	} = useAppSelector((state) => state.vehicleIncident);

	const [tabStates, setTabStates] = useState({
		section1: false,
		section2: false,
		section3: false,
		section4: false,
	});

	const [previousComponent, setPreviousComponent] = useState('section1');

	return (
		<div className="p-4 space-y-8 lg:w-[calc(100vw-10rem)]">
			<div className="flex space-x-10">
				<TabLink active={section === 'section1'} disabled={tabStates.section1}>
					Section 1-Description
				</TabLink>
				<TabLink active={section === 'section2'} disabled={tabStates.section2}>
					Section 2-Person Involved
				</TabLink>
				<TabLink active={section === 'section3'} disabled={tabStates.section3}>
					Section 3-Injury
				</TabLink>
				<TabLink active={section === 'section4'} disabled={tabStates.section4}>
					Section 4-Analysis
				</TabLink>
				<TabLink active={section === 'section5'}>
					Section 5-Investigation
				</TabLink>
			</div>
			<div className="h-0 border-t-[1px] border-primary-200 w-full my-5" />
			{section === 'section1' && (
				<IncidentSection1
					setSection={setSection}
					sectionId={incidentSection1Id}
					setTabStates={setTabStates}
					tabStates={tabStates}
				/>
			)}
			{section === 'section2' && (
				<IncidentSection2
					setSection={setSection}
					sectionId={incidentSection2Id}
					setTabStates={setTabStates}
					tabStates={tabStates}
					previousComponent={previousComponent}
					setPreviousComponent={setPreviousComponent}
				/>
			)}
			{section === 'section3' && (
				<IncidentSection3
					setSection={setSection}
					sectionId={incidentSection3Id}
					setTabStates={setTabStates}
					tabStates={tabStates}
					setPreviousComponent={setPreviousComponent}
				/>
			)}
			{section === 'section4' && (
				<IncidentSection4
					setSection={setSection}
					sectionId={incidentSection4Id}
					setTabStates={setTabStates}
					tabStates={tabStates}
					previousComponent={previousComponent}
					setPreviousComponent={setPreviousComponent}
				/>
			)}
			{section === 'section5' && (
				<IncidentSection5
					setSection={setSection}
					sectionId={incidentSection5Id}
					setTabStates={setTabStates}
					tabStates={tabStates}
					previousComponent={previousComponent}
				/>
			)}
		</div>
	);
};

export default IncidentPanel;
