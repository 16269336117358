import { useState, ReactNode } from 'react';
import Heading from 'components/atoms/Heading';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import SlideDown from 'components/atoms/SlideDown';

export interface IAccordion {
	title: string;
	children: ReactNode;
}

const Accordion: React.FC<IAccordion> = ({ title, children }) => {
	const [open, setOpen] = useState(false);
	return (
		<div className="flex flex-col max-w-[222px] justify-center my-4 mx-auto">
			<div className="flex w-full flex-row">
				<Heading type="h3" colour="primary" className="flex flex-1 my-auto">
					{title}
				</Heading>
				<button onClick={() => setOpen(!open)}>
					{open ? (
						<ChevronUpIcon
							className="h-5 w-5 text-primary"
							aria-hidden="true"
						/>
					) : (
						<ChevronDownIcon
							className="h-5 w-5 text-primary"
							aria-hidden="true"
						/>
					)}
				</button>
			</div>
			<SlideDown open={open} className={`${open ? 'my-2' : 'hidden'}`}>
				{children}
			</SlideDown>
		</div>
	);
};

export default Accordion;
