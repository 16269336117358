import { EyeIcon } from '@heroicons/react/outline';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Radio from 'components/atoms/Radio';
import Select from 'components/atoms/Select';
import Text from 'components/atoms/Text';
import React, { useState } from 'react';
import { useAssignFaultMutation } from 'redux/api/vehicleFaults';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { openCommentModal } from 'redux/slices/vehicleFaultSlice';
import { IVehicleFault } from 'types/VehicleFaults';

export interface IVehicleCard {
	fault: IVehicleFault;
}

const VehicleCard: React.FC<IVehicleCard> = ({ fault }) => {
	const [assignUser] = useAssignFaultMutation();
	const [status, setStatus] = useState(fault.status);
	const [assignee, setAssignee] = useState(fault.assignee?.id);
	const userPermission = useAppSelector((state) => state.user.permissions);
	const dispatch = useAppDispatch();

	const hasWriteAccess = () => {
		const permission = userPermission?.filter(
			(permission) =>
				permission.branch?.id === fault.branch?.id &&
				permission.fault_level === 'write'
		);

		if (!permission) {
			return false;
		}

		return permission.length > 0;
	};

	const handleOpenComments = () => {
		const payload = {
			commentFaultId: fault.id,
			faultBranchId: fault?.branch?.id,
		};
		dispatch(openCommentModal(payload));
	};

	const handleUpdateFault = () => {
		let assigneeId;

		fault?.managers.forEach((manager) => {
			if (`${manager.first_name} ${manager.last_name}` === assignee) {
				assigneeId = parseInt(manager.id);
			}
		});

		assignUser({
			id: fault.id,
			body: {
				assignee: assigneeId,
				status,
				ticket_number: fault?.ticket_number,
			},
		});
	};

	return (
		<div className="w-full px-10 pb-10 pt-8 border-t border-primary">
			<div className="flex">
				<div className={`flex-col w-3/4`}>
					<Heading type="h2" className="mb-6">
						Overview
					</Heading>
					<div className="flex pr-8 lg:pr-12 2xl:pr-32 justify-between space-x-8 lg:space-x-12 2xl:space-x-32">
						<div className="min-w-[200px]">
							<Text className="max-w-sm mb-2" type="bold">
								Description
							</Text>
							<Text className="max-w-sm mb-10 text-sm">
								{fault.fault_description}
							</Text>
						</div>
						<div className="flex-col w-64">
							<div className="max-h-[300px] overflow-auto pr-5 relative">
								<div className="flex font-semibold">
									<Text type="bold" className="flex basis-2/3 mr-4">
										Fault
									</Text>
									<Text type="bold" className="flex basis-1/3">
										Priority
									</Text>
								</div>
								{fault?.vehicle_faults.map((item) => (
									<div key={`${item.id}`}>
										<div className="flex py-4">
											<Text className="flex basis-2/3 mr-4 text-sm" type="bold">
												{item.fault_type}
											</Text>
											<Text className="flex basis-1/3 text-sm">
												{item.priority}
											</Text>
										</div>
										<div className="h-0 border-t-[1px] border-primary-200 w-full" />
									</div>
								))}
							</div>
						</div>
						<div className="hidden xl:flex xl:flex-col">
							<div className="mb-4 space-y-2">
								<Text type="bold">Created:</Text>
								<Text className="text-sm">
									{new Date(fault.submitted_at).toLocaleDateString('en-AU')}
								</Text>
							</div>
							<div className="mb-4 space-y-2">
								<Text type="bold">Last Action By:</Text>
								<Text className="text-sm">N. Flanders</Text>
							</div>
							<div className="mb-4 space-y-2">
								<Text type="bold">Escalation:</Text>
								<div>
									<Text className="text-sm">Escalation to BM: 26/07/21</Text>
									<Text className="text-sm">Escalation to RM: 02/08/21</Text>
								</div>
							</div>
							<div className="mb-4 space-y-2">
								<Text type="bold">Assigned to:</Text>
								<Text className="text-sm">Branch: {fault?.branch?.name}</Text>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-1 w-1/4 justify-end">
					<div className="max-w-sm w-full">
						<Text className="mb-5" type="bold">
							Comments
						</Text>
						<Button className="mr-5 font-bold" onClick={handleOpenComments}>
							<EyeIcon className="h-5 w-5 mr-1" />
							View Comments
						</Button>
						{hasWriteAccess() && (
							<>
								<div className="h-0 border-t-[1px] border-primary-200 w-full my-5" />
								<Text type="bold" className="mb-6">
									Assign to
								</Text>

								<Select
									options={fault?.managers.map((manager) => {
										return {
											id: manager.id,
											label: `${manager.first_name} ${manager.last_name}`,
										};
									})}
									selected={assignee}
									onChange={setAssignee}
									placeholder="Select name"
									className="w-full min-w-[200px]"
								/>
								<Text type="bold" className="mt-4">
									Status
								</Text>
								<Radio
									className="mb-7"
									classNameWrapper="space-x-4"
									options={[
										{ title: 'Open', id: 'open' },
										{ title: 'Closed', id: 'closed' },
									]}
									radioDirection="flex-row"
									defaultId={status}
									onChange={setStatus}
								/>
								<Button
									navigate="next"
									onClick={handleUpdateFault}
									className="font-bold"
								>
									Submit
								</Button>
							</>
						)}
					</div>
				</div>
			</div>

			<div className="flex justify-between mt-8 w-full xl:hidden">
				<div className="mb-4 space-y-2">
					<Text type="bold">Created:</Text>
					<Text className="text-sm">
						{new Date(fault.submitted_at).toLocaleDateString('en-AU')}
					</Text>
				</div>
				<div className="mb-4 space-y-2">
					<Text type="bold">Last Action By:</Text>
					<Text className="text-sm">N. Flanders</Text>
				</div>
				<div className="mb-4 space-y-2">
					<Text type="bold">Escalation:</Text>
					<div>
						<Text className="text-sm">Escalation to BM: 26/07/21</Text>
						<Text className="text-sm">Escalation to RM: 02/08/21</Text>
					</div>
				</div>
				<div className="mb-4 space-y-2">
					<Text type="bold">Assigned to:</Text>
					<Text className="text-sm">Branch: {fault?.branch?.name}</Text>
				</div>
			</div>
		</div>
	);
};

export default VehicleCard;
