import React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

export interface ISelect {
	label?: string;
	id?: string;
	options: { id: string; label: string }[];
	selected?: string;
	onChange?: (item: string) => void;
	placeholder?: string;
	className?: string;
	size?: 'normal' | 'small';
	required?: boolean;
	errorMessage?: string;
}

const Select: React.FC<ISelect> = ({
	label,
	options,
	selected: defaultSelected,
	onChange,
	placeholder = 'Select',
	className = '',
	size = 'normal',
	required = false,
	errorMessage,
}) => {
	const [selected, setSelected] = useState(defaultSelected);

	useEffect(() => {
		setSelected(defaultSelected);
	}, [defaultSelected]);

	const handleOnSelect = (item: string) => {
		setSelected(item);
		onChange?.(item);
	};

	const inputBaseStyle = errorMessage
		? 'block w-full border-red-300 focus:ring-red-500 focus:border-red-500 placeholder-red-300 focus:outline-none'
		: 'border-gray-300';

	return (
		<Listbox value={selected} onChange={handleOnSelect}>
			{({ open }) => (
				<>
					<Listbox.Label className="block font-inter text-base font-bold tracking-normal text-secondary">
						{label} {required && <span className="text-red">*</span>}
					</Listbox.Label>
					<div className={`relative ${className}`}>
						<Listbox.Button
							className={`
							${size === 'normal' ? 'h-12 py-3.5' : 'h-8 py-0'}
							border
							${open ? ' border-primary border-b-0 drop-shadow-lg' : `${inputBaseStyle}`}
							bg-white relative w-full px-4 text-left cursor-pointer focus:outline-none sm:text-sm min-w-[150px] mt-1 `}
						>
							<div className="flex">
								{selected === '' ? (
									<span className="block truncate flex-1 text-gray-500">
										{placeholder}
									</span>
								) : (
									<span className="block truncate flex-1">
										{options &&
											options.filter((option) => option.label === selected)[0]
												?.label}
									</span>
								)}
								<span className="flex items-center pointer-events-none">
									{open ? (
										<ChevronUpIcon
											className="h-5 w-5 text-primary"
											aria-hidden="true"
										/>
									) : (
										<ChevronDownIcon
											className="h-5 w-5 text-secondary"
											aria-hidden="true"
										/>
									)}
								</span>
							</div>

							{open && (
								<div className="absolute bottom-0 left-0 px-4 w-full">
									<div className="h-0 border-t-[0.25px] border-primary w-full" />
								</div>
							)}
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options
								static
								className="absolute z-10 py-2 w-full bg-white cursor-pointer text-base sm:text-sm border border-primary border-t-0 drop-shadow-lg"
							>
								{options.map((option, idx) => (
									<Listbox.Option
										key={idx}
										className={
											'text-secondary select-none py-3 px-4 cursor-pointer'
										}
										value={option.label}
									>
										<span className={'font-normal block truncate'}>
											{option.label}
										</span>
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
					{errorMessage && (
						<p className="mt-2 text-sm text-red-600" id="email-error">
							{errorMessage}
						</p>
					)}
				</>
			)}
		</Listbox>
	);
};

export default Select;
