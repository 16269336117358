import React, { useState } from 'react';
import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import Select from 'components/atoms/Select';
import CustomIcon from 'components/atoms/CustomIcon';
import { INavigation } from 'types/Layout';
import { useAppSelector } from 'redux/hooks';
import DefaultLogo from 'assets/images/default-avatar.png';
import { surveyTypeOptions } from 'types/Surveys';
import { useAppDispatch } from 'redux/hooks';
import { updateSurveyType } from 'redux/slices/surveys';

export interface IHeader {
	data?: INavigation;
	onLogout: () => void;
	onEdit: () => void;
	surveyDropdown?: boolean;
}

const Header: React.FC<IHeader> = ({
	data = null,
	onLogout,
	onEdit,
	surveyDropdown,
}) => {
	const user = useAppSelector((state) => state.user);
	const surveyState = useAppSelector((state) => state.surveys.surveyType);
	const [surveyType, setSurveyType] = useState(surveyState);
	const dispatch = useAppDispatch();

	const handleSurveyChange = (value: string) => {
		setSurveyType(value);
		dispatch(updateSurveyType(value));
	};

	return (
		<div className="flex flex-row w-full h-[108px] py-7 px-[50px] gap-x-8 border-b border-gray-300 z-40 bg-white">
			<div className="flex flex-1 space-x-8">
				{data ? (
					<>
						<div className="my-auto">
							<CustomIcon iconTypes={data.name} iconState="hover" size="lg" />
						</div>
						<Heading type="h1" className="my-auto">
							{data.name}
						</Heading>
						{surveyDropdown && (
							<Select
								options={surveyTypeOptions}
								onChange={(value: string) => handleSurveyChange(value)}
								className="w-48 z-[35]"
								selected={surveyType}
							/>
						)}
					</>
				) : (
					<Heading type="h1">Welcome Back</Heading>
				)}
			</div>
			<div className="flex flex-row h-[50px] my-auto gap-x-4">
				<button className="m-auto" onClick={onLogout}>
					<Text>Logout</Text>
				</button>
				<div className="m-auto">|</div>
				<button className="m-auto" onClick={onEdit}>
					<Text>Edit</Text>
				</button>
				<button className="m-auto">
					<img
						className="h-[50px] w-[50px] rounded-full"
						src={user.avatar ? user.avatar : DefaultLogo}
						alt=""
					/>
				</button>
			</div>
		</div>
	);
};

export default Header;
